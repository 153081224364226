import Header from "components/Header";
import Title from "components/Title";
import Typography from "components/Typography";
import Button from "components/Button";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

import product1 from "assets/images/products/1.png";
import product2 from "assets/images/products/2.png";
import product3 from "assets/images/products/3.png";
import product4 from "assets/images/products/4.png";
import product5 from "assets/images/products/5.png";

import { paths } from "constants/paths";

import "./styles.scss";

export const data1 = [
  {
    id: 1,
    title:
      "LINERASE - гидролизованный гетерологичный коллаген I типа животного происхождения.",
    label: "100 мг",
    price: "5300 ₽",
    count: 2,
    image: product1,
  },
  {
    id: 2,
    title: "Мезопрепарат Nithya Stimulate",
    label: "5 мл",
    price: "2099 ₽",
    count: 2,
    image: product2,
  },
  {
    id: 3,
    title: "Анти-акне пилинг для домашнего ухода (ANTI-ACNE PEEL) biOtime",
    label: "15 мл",
    price: "7320 ₽",
    count: 2,
    image: product3,
  },
];

export const data2 = [
  {
    id: 3,
    title:
      "Q73 Balancing Day Hydro Cream + Squalene- Увлажняющий крем со скваленовым маслом",
    label: "50 мг",
    price: "3938 ₽",
    count: 1,
    image: product4,
  },
  {
    id: 4,
    title: "Q85 HYDRO-Light - Молекулярная вода",
    label: "50 мл",
    price: "1100 ₽",
    count: 1,
    image: product5,
  },
];

const Count = ({ count }: { count: number }) => (
  <div className="count">
    <button className="count__button">
      <MinusIcon />
    </button>

    <span className="count__result">{count}</span>

    <button className="count__button">
      <PlusIcon />
    </button>
  </div>
);

const CartPage = () => {
  const title = "Моя корзина";

  return (
    <div className="wrapper">
      <Title>{title}</Title>

      <Header />

      <div className="cart">
        <Typography variant="h3" style={{ marginBottom: 8 }}>
          {title}
        </Typography>
        <div className="cart__departure">
          <div className="cart__header">
            <div className="cart__header__item">
              <div className="cart__header__title">Отправление №1</div>
              <ArrowIcon className="cart__header__arrow" />
            </div>
            <div className="cart__header__item">
              <div className="cart__header__price">10 719 ₽</div>
              <div className="cart__header__count">5 товаров</div>
            </div>
          </div>
          <div className="cart__list">
            {data1.map((el) => (
              <div className="cart__list__item" key={el.id}>
                <img
                  src={el.image}
                  className="cart__list__item__image"
                  alt="product"
                />
                <div className="cart__list__item__content">
                  <div className="cart__list__item__content__item">
                    <div className="cart__list__item__title">{el.title}</div>
                  </div>
                  <div className="cart__list__item__content__item">
                    <div className="cart__list__item__label">{el.label}</div>
                  </div>
                  <div className="cart__list__item__content__item">
                    <Count count={el.count} />
                    <div className="cart__list__item__price">{el.price}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="cart__departure">
          <div className="cart__header">
            <div className="cart__header__item">
              <div className="cart__header__title">Отправление №1</div>
              <ArrowIcon className="cart__header__arrow" />
            </div>
            <div className="cart__header__item">
              <div className="cart__header__price">5038 ₽</div>
              <div className="cart__header__count">2 товаров</div>
            </div>
          </div>
          <div className="cart__list">
            {data2.map((el) => (
              <div className="cart__list__item" key={el.id}>
                <img
                  src={el.image}
                  className="cart__list__item__image"
                  alt="product"
                />
                <div className="cart__list__item__content">
                  <div className="cart__list__item__content__item">
                    <div className="cart__list__item__title">{el.title}</div>
                  </div>
                  <div className="cart__list__item__content__item">
                    <div className="cart__list__item__label">{el.label}</div>
                  </div>
                  <div className="cart__list__item__content__item">
                    <Count count={el.count} />
                    <div className="cart__list__item__price">{el.price}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="cart-total">
          <div className="cart-total__inner">
            <div className="cart-total__title">Сумма заказа</div>
            <div className="cart-total__item">
              <div className="cart-total__count">Товары, 7 шт.</div>
              <div className="cart-total__price-1">15 757 ₽</div>
            </div>

            <div className="cart-total__item">
              <div className="cart-total__result">Итоговая стоимость</div>
              <div className="cart-total__price-2">16 057 ₽</div>
            </div>

            <div className="cart-total__button">
              <Button to={paths.order} size="large" full>
                Перейти к оформлению
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
