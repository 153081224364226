import Typography from "components/Typography";

import "./styles.scss";
import { CSSProperties, DetailedHTMLProps, InputHTMLAttributes } from "react";
import { ControllerFieldState } from "react-hook-form";

type InputProps = {
  label?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  type?: string;
  value?: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  disabled?: boolean;
} & Partial<ControllerFieldState> &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input: React.FC<InputProps> = ({
  label,
  className,
  required,
  onChange,
  style,
  error,
  ...inputProps
}) => {
  return (
    <div className={`input ${className}`} style={style}>
      {label && (
        <Typography
          className={`input__label ${required ? "input__label_required" : ""}`}
        >
          {label}
        </Typography>
      )}
      <div className="input__area">
        <input
          {...inputProps}
          className="input__text-area"
          onChange={(e) => onChange?.(e.target.value)}
        />
      </div>
      {error && (
        <Typography className={`input__error`}>{error.message}</Typography>
      )}
    </div>
  );
};

export default Input;
