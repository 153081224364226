import { CSSProperties, FC, MouseEventHandler, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

type Variant = "text" | "contained" | "outlined" | "second";

type ButtonProps = {
  to?: string;
  full?: boolean;
  size?: "small" | "medium" | "large";
  style?: CSSProperties;
  onClick?:
    | MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    | undefined;
  startIcon?: React.ReactNode;
  variant?: Variant;
  state?: Object;
};

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  to,
  full,
  size = "medium",
  style,
  onClick,
  children,
  startIcon,
  variant = "contained",
  state,
}) => {
  if (to) {
    return (
      <Link
        to={to}
        className={`button ${full ? "button_full" : ""} ${
          size ? "button_" + size : ""
        } button_${variant}`}
        style={style}
        onClick={onClick}
        state={state}
      >
        {startIcon && <span className="button__icon">{startIcon}</span>}
        {children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      className={`button ${full ? "button_full" : ""} ${
        size ? "button_" + size : ""
      } button_${variant}`}
      style={style}
      onClick={onClick}
    >
      {startIcon && <span className="button__icon">{startIcon}</span>}
      {children}
    </button>
  );
};

export default Button;
