export const chapterData = [
  {
    id: 1,
    text: "Биоревитализанты",
  },
  {
    id: 2,
    text: "Филлеры",
  },
  {
    id: 3,
    text: "Мезопрепараты",
  },
  {
    id: 4,
    text: "Профессиональная космецевтика",
  },
  {
    id: 5,
    text: "Пилинги",
  },
  {
    id: 6,
    text: "Биорепаранты",
  },
  {
    id: 7,
    text: "Иглы, канюли и шприцы",
  },
  {
    id: 8,
    text: "Коллаген и коллагеностимуляторы",
  },
  {
    id: 9,
    text: "Мезонити",
  },
  {
    id: 10,
    text: "Расходные материалы",
  },
  {
    id: 11,
    text: "Карбокситерапия",
  },
  {
    id: 12,
    text: "БАДы и пищевые добавки",
  },
  {
    id: 13,
    text: "Депиляция",
  },
];
