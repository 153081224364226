"use client";
import { Link, useNavigate } from "react-router-dom";

import { paths } from "constants/paths";

import Title from "components/Title";
import Header from "components/Header";
import Button from "components/Button";
import Input from "components/Input";

import logo from "assets/icons/logo.svg";

import "./styles.scss";
import { useUsersPermissionsAuthServicePostAuthLocal } from "openapi/swagger/queries";
import { Controller, useForm } from "react-hook-form";
import { EMAIL_REGEX } from "constants/form";

type SignInFields = {
  email: string;
  password: string;
};

const SignInPage = () => {
  const title = "Вход";

  const navigate = useNavigate();

  const { mutateAsync } = useUsersPermissionsAuthServicePostAuthLocal();

  const { control, handleSubmit, setError } = useForm<SignInFields>();

  const handleSignin = async ({ email, password }: SignInFields) => {
    try {
      await mutateAsync({
        requestBody: { identifier: email, password },
      });
      navigate(paths.account);
    } catch (error) {
      setError("email", { message: "Неверный логин или пароль" });
    }
  };

  return (
    <div className="wrapper">
      <Title>{title}</Title>

      <Header goBack title="Вход" />

      <div className="sign-in">
        <img src={logo} alt="Logo" className="sign-in__logo" />

        <Controller
          name="email"
          control={control}
          rules={{
            required: "Поле обязательно",
            pattern: {
              message: "Неверная почта",
              value: EMAIL_REGEX,
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              {...fieldState}
              {...field}
              required
              label="Ваш e-mail"
              placeholder="userexample@mail.ru"
              className="sign-in__input"
              type={"email"}
              autoComplete="email"
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          rules={{
            required: "Поле обязательно",
          }}
          render={({ field, fieldState }) => (
            <Input
              {...fieldState}
              {...field}
              required
              label="Пароль"
              className="sign-in__input"
              type={"password"}
              autoComplete="current-password"
            />
          )}
        />

        <Link to={paths.forgotPassword} className="sign-in__forgot-password">
          Забыли пароль?
        </Link>

        <Button full size="large" onClick={handleSubmit(handleSignin)}>
          Войти
        </Button>
      </div>
    </div>
  );
};

export default SignInPage;
