import Header from "components/Header";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";
import TextArea from "components/TextArea";

import { ReactComponent as ImageIcon } from "assets/icons/gallery-add.svg";

import { paths } from "constants/paths";
import "./styles.scss";

const AccountBrandsCreate = () => {
  const title = "Создание бренда";
  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header goBack title={title} />
        <div className="account-brands-create">
          <Input
            required
            label="Название бренда"
            className="account-brands-create__input"
            placeholder="Cosmetic name"
          />

          <div className="account-brands-create__logo">
            <div className="account-brands-create__image">
              <ImageIcon />
            </div>
            <div className="account-brands-create__logo__content">
              <div className="account-brands-create__logo__content__title">
                Логотип бренда
              </div>
              <div className="account-brands-create__logo__content__text">
                в формате PNG, JPEG до 10 Мб
              </div>
            </div>
          </div>

          <TextArea label="Описание" placeholder="Кратко опишите бренд" />

          <Button
            full
            size="large"
            style={{ marginTop: 24 }}
            to={paths.accountBrands}
          >
            Создать
          </Button>
        </div>
      </div>
    </>
  );
};

export default AccountBrandsCreate;
