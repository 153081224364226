import Crumbs from "components/Crumbs";
import Footer from "components/Footer";
import Header from "components/Header";
import Title from "components/Title";
import Typography from "components/Typography";
import Input from "components/Input";
import Button from "components/Button";

import "./styles.scss";
import path from "path";
import { paths } from "constants/paths";

const AccountProfilePage = () => {
  const title = "Личные данные";

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header />

        <div className="account-profile">
          <div className="account-profile__header">
            <Crumbs path={title || ""} />
            <Typography variant="h3">{title}</Typography>
          </div>
          <div className="account-profile__inner">
            <div className="account-profile__content">
              <Input
                required
                label="ФИО ответственного"
                placeholder="Иванов Иван Иванович"
                className="sign-up__input"
                disabled
                value="Ломоносов Евгений Николаевич"
              />

              <Input
                required
                label="Название компании"
                placeholder="Компания"
                className="sign-up__input"
                disabled
                value="ООО «Косметика PRO»"
              />

              <Input
                required
                label="E-mail"
                placeholder="userexample@mail.ru"
                className="sign-up__input"
                type={"email"}
                disabled
                value="userexample@mail.ru"
              />

              <Input
                label="Номер телефона"
                placeholder="+7 952 325 96 70"
                className="sign-up__input"
              />

              <Input
                required
                label="Ваш город"
                placeholder="Москва"
                className="sign-up__input"
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Input
                  required
                  label="Пароль"
                  placeholder="Введите пароль"
                  className="sign-up__input"
                  type="password"
                  style={{ flex: 1, marginRight: 4 }}
                />
                <Button
                  to={paths.password}
                  variant="outlined"
                  style={{ height: "44px", marginTop: 4 }}
                >
                  Изменить
                </Button>
              </div>
            </div>
          </div>

          <div className="account-profile__support">
            <Typography className="account-profile__support__text">
              Для изменения ФИО, E-mail, сертификации обратитесь в поддержку
              сервиса CosmeticZone
            </Typography>
            <Button to={paths.support} full size="large">
              Написать в поддержку
            </Button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AccountProfilePage;
