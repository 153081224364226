import { useState } from "react";
import { Sheet } from "react-modal-sheet";

import { ReactComponent as CloseIcon } from "assets/icons/burger-close.svg";

import Typography from "components/Typography";

import "./styles.scss";

enum SortingEnum {
  NAME,
  PRICE_DESC,
  PRICE_ASC,
}

type SortingProps = {
  isOpen: boolean;
  setOpen?: (isOpen: boolean) => void;
};

const Sorting: React.FC<SortingProps> = ({ isOpen, setOpen }) => {
  const [sorting, setSorting] = useState(SortingEnum.NAME);

  const onSelect = (sort: SortingEnum) => {
    setSorting(sort);
  };

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setOpen?.(false)}
      detent={"content-height"}
    >
      <Sheet.Container className="sorting__react-modal-sheet-container">
        <Sheet.Content>
          <div className="sorting">
            <div className="sorting__header">
              <div className="sorting__header__title">Сортировка</div>
              <button
                className="sorting__header__close"
                onClick={() => setOpen?.(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <ul className="sorting__items">
              <li
                className="sorting__item"
                onClick={() => onSelect(SortingEnum.NAME)}
              >
                <Typography variant="subtitle2">По наименованию</Typography>
                <button
                  className={`sorting__item__button ${
                    sorting === SortingEnum.NAME
                      ? "sorting__item__button-active"
                      : ""
                  }`}
                />
              </li>
              <li
                className="sorting__item"
                onClick={() => onSelect(SortingEnum.PRICE_DESC)}
              >
                <Typography variant="subtitle2">По убыванию цены</Typography>
                <button
                  className={`sorting__item__button ${
                    sorting === SortingEnum.PRICE_DESC
                      ? "sorting__item__button-active"
                      : ""
                  }`}
                />
              </li>
              <li
                className="sorting__item"
                onClick={() => onSelect(SortingEnum.PRICE_ASC)}
              >
                <Typography variant="subtitle2">По возрастанию цены</Typography>
                <button
                  className={`sorting__item__button ${
                    sorting === SortingEnum.PRICE_ASC
                      ? "sorting__item__button-active"
                      : ""
                  }`}
                />
              </li>
            </ul>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};

export default Sorting;
