import product1 from "assets/images/products/1.png";
import product2 from "assets/images/products/2.png";
import product3 from "assets/images/products/3.png";
import product4 from "assets/images/products/4.png";
import product5 from "assets/images/products/5.png";
import product6 from "assets/images/products/6.png";

export const productsData = [
  {
    id: 1,
    brand: "Eldermafill",
    title: "Collagen Ampoule + Collagen Activator",
    price: "8200 ₽",
    image: product1,
  },
  {
    id: 2,
    brand: "CELLVIDERM",
    title: "СФЕРО®гель Medium",
    price: "11 780 ₽",
    image: product2,
  },
  {
    id: 3,
    brand: "Euroresearch s.r.l.",
    title: "Мезопрепарат Nithya Stimulate",
    price: "2099 ₽",
    image: product3,
  },
  {
    id: 4,
    brand: "Бьюти Эксперт",
    title:
      "LINERASE - гидролизованный гетерологичный коллаген I типа животного происхождения.",
    price: "12 000 ₽",
    image: product4,
  },
  {
    id: 5,
    brand: "Innovation",
    title:
      "Moist Collagen Jelly-Коллагеновое желе со вкусом винограда (СРОК 09/24)",
    price: "2050 ₽",
    image: product5,
  },
  {
    id: 6,
    brand: "SL BEAUTY",
    title: "Анти-акне пилинг для домашнего ухода (ANTI-ACNE PEEL) biOtime",
    price: "3660 ₽",
    image: product6,
  },
];
