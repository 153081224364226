// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { AdminService, AuthorizationService, AuthService, ClientService, UploadService } from "../requests/services.gen";
import * as Common from "./common";
/**
* login
* Generated from cURL: curl 'http://localhost:1337/admin/login' \
* -H 'Accept-Language: ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7' \
* -H 'Connection: keep-alive' \
* -H 'Cookie: next-auth.csrf-token=0532951a93a6caca873b0c9cb2046aa905f63b5c04b13598802d1129ed443494%7C9f3cb7f93b4a32269631e864b8f545e1542599c836db338d78b04bbc47350b8f; authjs.callback-url=http%3A%2F%2Flocalhost%3A3000; authjs.csrf-token=4e9fd87f21f19b7bf2206f1b2975506bd15bd01ff7ec27d1d8395d71c7c58828%7C711bdd00c268b78f9f176c9e30ba1293caf769931f9500efae84852e8b928df0; next-auth.callback-url=http%3A%2F%2Flocalhost%3A3000%2Fme' \
* -H 'Origin: http://localhost:1337' \
* -H 'Referer: http://localhost:1337/admin/auth/login?redirectTo=%2Fcontent-manager' \
* -H 'Sec-Fetch-Dest: empty' \
* -H 'Sec-Fetch-Mode: cors' \
* -H 'Sec-Fetch-Site: same-origin' \
* -H 'User-Agent: Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Mobile Safari/537.36' \
* -H 'accept: application/json' \
* -H 'authorization: Bearer' \
* -H 'content-type: application/json' \
* -H 'sec-ch-ua: "Google Chrome";v="131", "Chromium";v="131", "Not_A Brand";v="24"' \
* -H 'sec-ch-ua-mobile: ?1' \
* -H 'sec-ch-ua-platform: "Android"' \
* --data-raw '{"email":"losakovvitalik@gmail.com","password":"sC28D2bnbLnQ78"}'
* @param data The data for the request.
* @param data.acceptLanguage
* @param data.connection
* @param data.cookie
* @param data.origin
* @param data.referer
* @param data.secFetchDest
* @param data.secFetchMode
* @param data.secFetchSite
* @param data.userAgent
* @param data.secChUa
* @param data.secChUaMobile
* @param data.secChUaPlatform
* @param data.requestBody
* @returns unknown 200
* @throws ApiError
*/
export const useAdminServiceLogin = <TData = Common.AdminServiceLoginMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  acceptLanguage?: string;
  connection?: string;
  cookie?: string;
  origin?: string;
  referer?: string;
  requestBody?: { email?: string; password?: string; };
  secChUa?: string;
  secChUaMobile?: string;
  secChUaPlatform?: string;
  secFetchDest?: string;
  secFetchMode?: string;
  secFetchSite?: string;
  userAgent?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  acceptLanguage?: string;
  connection?: string;
  cookie?: string;
  origin?: string;
  referer?: string;
  requestBody?: { email?: string; password?: string; };
  secChUa?: string;
  secChUaMobile?: string;
  secChUaPlatform?: string;
  secFetchDest?: string;
  secFetchMode?: string;
  secFetchSite?: string;
  userAgent?: string;
}, TContext>({ mutationFn: ({ acceptLanguage, connection, cookie, origin, referer, requestBody, secChUa, secChUaMobile, secChUaPlatform, secFetchDest, secFetchMode, secFetchSite, userAgent }) => AdminService.login({ acceptLanguage, connection, cookie, origin, referer, requestBody, secChUa, secChUaMobile, secChUaPlatform, secFetchDest, secFetchMode, secFetchSite, userAgent }) as unknown as Promise<TData>, ...options });
/**
* login
* Generated from cURL: curl 'http://localhost:1337/admin/login' \
* -H 'Accept-Language: ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7' \
* -H 'Connection: keep-alive' \
* -H 'Cookie: next-auth.csrf-token=0532951a93a6caca873b0c9cb2046aa905f63b5c04b13598802d1129ed443494%7C9f3cb7f93b4a32269631e864b8f545e1542599c836db338d78b04bbc47350b8f; authjs.callback-url=http%3A%2F%2Flocalhost%3A3000; authjs.csrf-token=4e9fd87f21f19b7bf2206f1b2975506bd15bd01ff7ec27d1d8395d71c7c58828%7C711bdd00c268b78f9f176c9e30ba1293caf769931f9500efae84852e8b928df0; next-auth.callback-url=http%3A%2F%2Flocalhost%3A3000%2Fme' \
* -H 'Origin: http://localhost:1337' \
* -H 'Referer: http://localhost:1337/admin/auth/login?redirectTo=%2Fcontent-manager' \
* -H 'Sec-Fetch-Dest: empty' \
* -H 'Sec-Fetch-Mode: cors' \
* -H 'Sec-Fetch-Site: same-origin' \
* -H 'User-Agent: Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Mobile Safari/537.36' \
* -H 'accept: application/json' \
* -H 'authorization: Bearer' \
* -H 'content-type: application/json' \
* -H 'sec-ch-ua: "Google Chrome";v="131", "Chromium";v="131", "Not_A Brand";v="24"' \
* -H 'sec-ch-ua-mobile: ?1' \
* -H 'sec-ch-ua-platform: "Android"' \
* --data-raw '{"email":"losakovvitalik@gmail.com","password":"sC28D2bnbLnQ78"}'
* @param data The data for the request.
* @param data.acceptLanguage
* @param data.connection
* @param data.cookie
* @param data.origin
* @param data.referer
* @param data.secFetchDest
* @param data.secFetchMode
* @param data.secFetchSite
* @param data.userAgent
* @param data.secChUa
* @param data.secChUaMobile
* @param data.secChUaPlatform
* @param data.requestBody
* @returns unknown 200
* @throws ApiError
*/
export const useAuthorizationServiceLogin = <TData = Common.AuthorizationServiceLoginMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  acceptLanguage?: string;
  connection?: string;
  cookie?: string;
  origin?: string;
  referer?: string;
  requestBody?: { email?: string; password?: string; };
  secChUa?: string;
  secChUaMobile?: string;
  secChUaPlatform?: string;
  secFetchDest?: string;
  secFetchMode?: string;
  secFetchSite?: string;
  userAgent?: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  acceptLanguage?: string;
  connection?: string;
  cookie?: string;
  origin?: string;
  referer?: string;
  requestBody?: { email?: string; password?: string; };
  secChUa?: string;
  secChUaMobile?: string;
  secChUaPlatform?: string;
  secFetchDest?: string;
  secFetchMode?: string;
  secFetchSite?: string;
  userAgent?: string;
}, TContext>({ mutationFn: ({ acceptLanguage, connection, cookie, origin, referer, requestBody, secChUa, secChUaMobile, secChUaPlatform, secFetchDest, secFetchMode, secFetchSite, userAgent }) => AuthorizationService.login({ acceptLanguage, connection, cookie, origin, referer, requestBody, secChUa, secChUaMobile, secChUaPlatform, secFetchDest, secFetchMode, secFetchSite, userAgent }) as unknown as Promise<TData>, ...options });
/**
* register cosmetologist
* register cosmetologist
* @param data The data for the request.
* @param data.requestBody
* @returns unknown 200
* @throws ApiError
*/
export const useClientServiceRegisterCosmetologist = <TData = Common.ClientServiceRegisterCosmetologistMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { certificate?: Array<(number)>; degree?: Array<(number)>; passport?: Array<(number)>; }; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { certificate?: Array<(number)>; degree?: Array<(number)>; passport?: Array<(number)>; }; };
}, TContext>({ mutationFn: ({ requestBody }) => ClientService.registerCosmetologist({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* register clinic
* register clinic
* @param data The data for the request.
* @param data.requestBody
* @returns unknown 200
* @throws ApiError
*/
export const useClientServiceRegisterClinic = <TData = Common.ClientServiceRegisterClinicMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { certificate?: Array<(number)>; company?: string; }; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { certificate?: Array<(number)>; company?: string; }; };
}, TContext>({ mutationFn: ({ requestBody }) => ClientService.registerClinic({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* register partner
* register partner
* @param data The data for the request.
* @param data.requestBody
* @returns unknown 200
* @throws ApiError
*/
export const useClientServiceRegisterPartner = <TData = Common.ClientServiceRegisterPartnerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { company?: string; moderation?: Array<(number)>; }; token?: string; username?: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { company?: string; moderation?: Array<(number)>; }; token?: string; username?: string; };
}, TContext>({ mutationFn: ({ requestBody }) => ClientService.registerPartner({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* create
* create
* @param data The data for the request.
* @param data.formData
* @returns unknown 201
* @throws ApiError
*/
export const useClientServiceCreate = <TData = Common.ClientServiceCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData?: { files?: (Blob | File); };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData?: { files?: (Blob | File); };
}, TContext>({ mutationFn: ({ formData }) => ClientService.create({ formData }) as unknown as Promise<TData>, ...options });
/**
* register cosmetologist
* register cosmetologist
* @param data The data for the request.
* @param data.requestBody
* @returns unknown 200
* @throws ApiError
*/
export const useAuthServiceRegisterCosmetologist = <TData = Common.AuthServiceRegisterCosmetologistMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { certificate?: Array<(number)>; degree?: Array<(number)>; passport?: Array<(number)>; }; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { certificate?: Array<(number)>; degree?: Array<(number)>; passport?: Array<(number)>; }; };
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.registerCosmetologist({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* register clinic
* register clinic
* @param data The data for the request.
* @param data.requestBody
* @returns unknown 200
* @throws ApiError
*/
export const useAuthServiceRegisterClinic = <TData = Common.AuthServiceRegisterClinicMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { certificate?: Array<(number)>; company?: string; }; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { certificate?: Array<(number)>; company?: string; }; };
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.registerClinic({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* register partner
* register partner
* @param data The data for the request.
* @param data.requestBody
* @returns unknown 200
* @throws ApiError
*/
export const useAuthServiceRegisterPartner = <TData = Common.AuthServiceRegisterPartnerMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { company?: string; moderation?: Array<(number)>; }; token?: string; username?: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody?: { city?: string; email?: string; name?: string; password?: string; phone?: string; settings?: { company?: string; moderation?: Array<(number)>; }; token?: string; username?: string; };
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.registerPartner({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* create
* create
* @param data The data for the request.
* @param data.formData
* @returns unknown 201
* @throws ApiError
*/
export const useUploadServiceCreate = <TData = Common.UploadServiceCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  formData?: { files?: (Blob | File); };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  formData?: { files?: (Blob | File); };
}, TContext>({ mutationFn: ({ formData }) => UploadService.create({ formData }) as unknown as Promise<TData>, ...options });
/**
* delete file
* delete file
* @param data The data for the request.
* @param data.id
* @returns unknown 200
* @throws ApiError
*/
export const useClientServiceDeleteFile = <TData = Common.ClientServiceDeleteFileMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => ClientService.deleteFile({ id }) as unknown as Promise<TData>, ...options });
/**
* delete file
* delete file
* @param data The data for the request.
* @param data.id
* @returns unknown 200
* @throws ApiError
*/
export const useUploadServiceDeleteFile = <TData = Common.UploadServiceDeleteFileMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => UploadService.deleteFile({ id }) as unknown as Promise<TData>, ...options });
