import { Link } from "react-router-dom";
import { paths } from "constants/paths";
import "./styles.scss";

type CrumbsProps = {
  path: string;
};

const Crumbs: React.FC<CrumbsProps> = ({ path }) => {
  return (
    <nav className="crumbs">
      <ul className="crumbs__wrap">
        <li className="crumbs__item caption2">
          <Link to={paths.home} className="crumbs__link" title="Главная">
            <span>Главная</span>{" "}
          </Link>
        </li>
        <li className="crumbs__item caption2">
          <span className="crumbs__link" title="О компании">
            <span> {path}</span>
          </span>
        </li>
      </ul>
    </nav>
  );
};

export default Crumbs;
