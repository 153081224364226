import { Link } from "react-router-dom";
import Crumbs from "components/Crumbs";
import Footer from "components/Footer";
import Header from "components/Header";
import Title from "components/Title";
import Typography from "components/Typography";
import Button from "components/Button";

import "./styles.scss";

import image1 from "assets/images/products/1.png";
import image2 from "assets/images/products/2.png";
import image3 from "assets/images/products/3.png";
import { paths } from "constants/paths";

const AccountOrdersIdPage = () => {
  const title = "Мои заказы";

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header />

        <div className="account-order">
          <div className="account-order__header">
            <Crumbs path={title || ""} />
            <Typography variant="h3">{title}</Typography>
            <div className="account-order__tabs">
              <Button>Все</Button>
              <Button variant="second">Активные</Button>
              <Button variant="second">Завершенные</Button>
            </div>
          </div>
          <div className="account-order__list">
            <Link
              to={`${paths.accountOrders}/1`}
              className="account-order-card"
            >
              <div className="account-order-card__content">
                <div className="account-order-card__item">
                  <Typography className="account-order-card__date">
                    Заказ от 1 сентября
                  </Typography>
                  <Typography className="account-order-card__status">
                    Доставлены
                  </Typography>
                </div>
                <Typography className="account-order-card__number">
                  №1177738-1
                </Typography>
                <div className="account-order-card__item">
                  <Typography className="account-order-card__price">
                    10 719 ₽
                  </Typography>
                  <Typography className="account-order-card__order">
                    5 товаров
                  </Typography>
                </div>
                <Typography className="account-order-card__address-title">
                  Доставка по адресу
                </Typography>
                <Typography className="account-order-card__address">
                  Будапештская улица, 46, Санкт-Петербург
                </Typography>
                <Typography className="account-order-card__track">
                  Трек-номер
                </Typography>
                <Typography className="account-order-card__track-number">
                  10025953574
                </Typography>
                <div>
                  <img
                    className="account-order-card__image"
                    src={image1}
                    alt="Order"
                  />
                  <img
                    className="account-order-card__image"
                    src={image2}
                    alt="Order"
                  />
                  <img
                    className="account-order-card__image"
                    src={image3}
                    alt="Order"
                  />
                </div>
              </div>
            </Link>

            <Link
              to={`${paths.accountOrders}/1`}
              className="account-order-card"
            >
              <div className="account-order-card__content">
                <div className="account-order-card__item">
                  <Typography className="account-order-card__date">
                  Заказ от 29 августа
                  </Typography>
                  <Typography className="account-order-card__status">
                    Доставлены
                  </Typography>
                </div>
                <Typography className="account-order-card__number">
                  №1177738-1
                </Typography>
                <div className="account-order-card__item">
                  <Typography className="account-order-card__price">
                    10 719 ₽
                  </Typography>
                  <Typography className="account-order-card__order">
                    5 товаров
                  </Typography>
                </div>
                <Typography className="account-order-card__address-title">
                  Доставка по адресу
                </Typography>
                <Typography className="account-order-card__address">
                  Будапештская улица, 46, Санкт-Петербург
                </Typography>
                <Typography className="account-order-card__track">
                  Трек-номер
                </Typography>
                <Typography className="account-order-card__track-number">
                  10025953574
                </Typography>
                <div>
                  <img
                    className="account-order-card__image"
                    src={image1}
                    alt="Order"
                  />
                  <img
                    className="account-order-card__image"
                    src={image2}
                    alt="Order"
                  />
                  <img
                    className="account-order-card__image"
                    src={image3}
                    alt="Order"
                  />
                </div>
              </div>
            </Link>

            <Link
              to={`${paths.accountOrders}/1`}
              className="account-order-card"
            >
              <div className="account-order-card__content">
                <div className="account-order-card__item">
                  <Typography className="account-order-card__date">
                  Заказ от 24 августа
                  </Typography>
                  <Typography className="account-order-card__status">
                    Доставлены
                  </Typography>
                </div>
                <Typography className="account-order-card__number">
                  №1177738-1
                </Typography>
                <div className="account-order-card__item">
                  <Typography className="account-order-card__price">
                    10 719 ₽
                  </Typography>
                  <Typography className="account-order-card__order">
                    5 товаров
                  </Typography>
                </div>
                <Typography className="account-order-card__address-title">
                  Доставка по адресу
                </Typography>
                <Typography className="account-order-card__address">
                  Будапештская улица, 46, Санкт-Петербург
                </Typography>
                <Typography className="account-order-card__track">
                  Трек-номер
                </Typography>
                <Typography className="account-order-card__track-number">
                  10025953574
                </Typography>
                <div>
                  <img
                    className="account-order-card__image"
                    src={image1}
                    alt="Order"
                  />
                  <img
                    className="account-order-card__image"
                    src={image2}
                    alt="Order"
                  />
                  <img
                    className="account-order-card__image"
                    src={image3}
                    alt="Order"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AccountOrdersIdPage;
