import { Link } from "react-router-dom";
import "./styles.scss";
import { paths } from "constants/paths";

type EventProps = {
  id: number;
  day: string;
  number: string;
  month: string;
  label: string;
  text: string;
  address: string;
  edit?: boolean;
};

const Event: React.FC<EventProps> = ({
  id,
  day,
  number,
  month,
  label,
  text,
  address,
  edit,
}) => {
  return (
    <Link
      to={edit ? paths.accountEventsAdd : `${paths.events}/${id}`}
      className="event"
    >
      <div className="event__date">
        <div className="event__date__day">{day}</div>
        <div className="event__date__number">{number}</div>
        <div className="event__date__month">{month}</div>
      </div>
      <div className="event__content">
        <div className="event__content__label">{label}</div>
        <div className="event__content__text">{text}</div>
        <div className="event__content__address">{address}</div>
      </div>
    </Link>
  );
};

export default Event;
