export const eventsData = [
  {
    id: 1,
    day: "Пт",
    number: "13",
    month: "сен",
    label: "Euroresearch s.r.l.",
    text: 'СЕМИНАР. "2-х дневный интенсив-практикум по инъекциям: SKILL BOOST. Начни колоть уже завтра!"',
    address: "г Москва, пр-кт Вернадского, д 39",
  },
  {
    id: 2,
    day: "Сб",
    number: "14",
    month: "сен",
    label: "CELLVIDERM",
    text: 'СЕМИНАР. "2-х дневный интенсив-практикум по инъекциям: SKILL BOOST. Начни колоть уже завтра!"',
    address:
      'г Москва, проезд Берёзовой Рощи, д 8 (Центральный офис ООО "Астрея", 3-й этаж, вход со стороны двора',
  },
  {
    id: 3,
    day: "Пт",
    number: "20",
    month: "сен",
    label: "Академия Эстетики",
    text: "Базовый семинар. Химические пилинги в практике дерматолога и косметолога.",
    address: "Online, Zoom",
  },
  {
    id: 4,
    day: "Вт",
    number: "24",
    month: "окт",
    label: "Бьюти Эксперт",
    text: "Блиц-Вебинар. 90 минут! История одного пациента. Протокол №6 Нитевые технологии от простого к сложному",
    address: "г Москва, Дербеневская наб, д 1/2",
  },
  {
    id: 5,
    day: "Вс",
    number: "6",
    month: "окт",
    label: "SL BEAUTY",
    text: "Индивидуальное обучение мезотерапии и биоревитализации",
    address: "Онлайн",
  },
  {
    id: 6,
    day: "Вт",
    number: "8",
    month: "окт",
    label: "CELLVIDERM",
    text: "Индивидуальное обучение Контурной пластике лица. Базовый уровень.",
    address: "г Москва, Смоленский б-р, д 24 к 3",
  },
  {
    id: 7,
    day: "Ср",
    number: "9",
    month: "окт",
    label: "Euroresearch s.r.l.",
    text: "Миофасциальный массаж лица",
    address: "Online",
  },
];
