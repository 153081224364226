import Header from "components/Header";
import Title from "components/Title";
import Typography from "components/Typography";
import Button from "components/Button";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as DeliveryIcon } from "assets/icons/delivery.svg";
import { ReactComponent as CdekIcon } from "assets/icons/cdek.svg";
import { ReactComponent as SbpIcon } from "assets/icons/sbp.svg";
import { ReactComponent as CosmetologIcon } from "assets/icons/cosmetolog.svg";
import { ReactComponent as CardIcon } from "assets/icons/card.svg";

import image1 from "assets/images/products/1.png";
import image2 from "assets/images/products/2.png";
import image3 from "assets/images/products/3.png";
import image4 from "assets/images/products/4.png";
import image5 from "assets/images/products/5.png";

import { paths } from "constants/paths";
import "./styles.scss";

const OrderPage = () => {
  const title = "Оформление заказа";

  return (
    <div className="wrapper">
      <Title>{title}</Title>

      <Header />

      <div className="order">
        <Typography variant="h3" style={{ marginBottom: 16 }}>
          {title}
        </Typography>

        <div className="order__types">
          <div className="order__type order__type-active">
            <div className="order__type__title">Курьером</div>
            <div className="order__type__label">Все товары</div>
          </div>
          <div className="order__type">
            <div className="order__type__title">Пункт выдачи</div>
            <div className="order__type__label">Все товары</div>
          </div>
          <div className="order__type">
            <div className="order__type__title">Самовывоз</div>
            <div className="order__type__label">Все товары</div>
          </div>
        </div>

        <div className="order__section">
          <div className="order__item" style={{ marginTop: 16 }}>
            <div className="order__item__header">
              <DeliveryIcon className="order__item__header__icon" />
              <div className="order__item__header__title">
                Доставка по адресу
              </div>
            </div>

            <div className="order__item__select">
              <div className="order__item__select__text">
                улица Красного Курсанта, 25Ж, Санкт-Петербург
              </div>

              <ArrowIcon className={"order__item__select__arrow"} />
            </div>

            <div className="order__item__select order__item__select-selected">
              <div className="order__item__select__icon">
                <CdekIcon />
              </div>
              <div className="order__item__select__text">
                СДЭК (Курьерская служба){" "}
              </div>
            </div>
          </div>

          <div className="order__item">
            <div className="order__item__header">
              <CosmetologIcon className="order__item__header__icon" />
              <div className="order__item__header__title">Получатель</div>
            </div>

            <div className="order__item__recipient">
              Быкова Анастасия Михайловна <br />
              +7 952 325 96 70 <br />
              userexample@mail.ru
            </div>

            <div className="order__item__comment">Комментарий</div>

            <input
              type="text"
              className="order__item__input"
              placeholder="Комментарии к заказу"
            />
          </div>
        </div>

        <div className="cart-card">
          <div className="cart-card__inner">
            <div className="cart-card__header">
              <div className="cart-card__title">Отправление №1</div>
            </div>
            <div className="cart-card__images">
              <img className="cart-card__image" src={image1} />
              <img className="cart-card__image" src={image2} />
              <img className="cart-card__image" src={image3} />
            </div>

            <div className="cart-card__item">
              <div className="cart-card__count">5 товаров</div>
              <div className="cart-card__price">10 719 ₽</div>
            </div>
          </div>
        </div>

        <div className="cart-card">
          <div className="cart-card__inner">
            <div className="cart-card__header">
              <div className="cart-card__title">Отправление №2</div>
            </div>
            <div className="cart-card__images">
              <img className="cart-card__image" src={image4} />
              <img className="cart-card__image" src={image5} />
            </div>

            <div className="cart-card__item">
              <div className="cart-card__count">2 товара</div>
              <div className="cart-card__price">5038 ₽</div>
            </div>
          </div>
        </div>

        <div className="cart-card">
          <div className="cart-card__inner">
            <div className="cart-card__header">
              <div className="cart-card__title">Отправление №2</div>
            </div>

            <div className="order__item__select order__item__select-selected">
              <div className="order__item__select__icon">
                <SbpIcon />
              </div>
              <div className="order__item__select__text">СБП</div>
            </div>

            <div className="order__item__select order__item__select-selected">
              <div className="order__item__select__icon order__item__select__icon-disable">
                <CardIcon />
              </div>
              <div className="order__item__select__text">Новой картой</div>
            </div>
          </div>
        </div>

        <div className="cart-total">
          <div className="cart-total__inner">
            <div className="cart-total__title">Сумма заказа</div>
            <div className="cart-total__item">
              <div className="cart-total__count">Товары, 7 шт.</div>
              <div className="cart-total__price-1">15 757 ₽</div>
            </div>

            <div className="cart-total__item">
              <div className="cart-total__result">Итоговая стоимость</div>
              <div className="cart-total__price-2">16 057 ₽</div>
            </div>

            <div className="cart-total__button">
              <Button to={paths.home} size="large" full>
                Оформить заказ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
