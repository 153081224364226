import Title from "components/Title";
import Header from "components/Header";
import Crumbs from "components/Crumbs";
import Footer from "components/Footer";
import Slider from "components/Slider";
import Tabs from "components/Tabs";
import Typography from "components/Typography";
import ProductCharacteristics from "./components/ProductСharacteristics";
import ProductAccordion from "./components/ProductAccordion";

import useProductPage from "./useProductPage";

import productImage from "assets/images/product.png";

import "./styles.scss";

const ProductPage = () => {
  const { title, product } = useProductPage();

  return (
    <>
      <Title>{title}</Title>

      <Header />

      <div className="product">
        <div className="wrapper">
          <Crumbs path={title} />
        </div>

        <Tabs />

        <div className="wrapper">
          <Slider
            data={[
              {
                id: 1,
                image: productImage,
              },
              {
                id: 3,
                image: productImage,
              },
            ]}
          />

          <Typography variant="body1" className="product__label">
            {product?.brand}
          </Typography>

          <Typography variant="h3" className="product__title">
            {title}
          </Typography>

          <Typography variant="h3" className="product__price">
            {product?.price}
          </Typography>

          <ProductCharacteristics />

          <ProductAccordion />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProductPage;
