import { useState } from "react";
import { useLocation } from "react-router-dom";

const useCatalogIdPage = () => {
  const { state } = useLocation();
  const title = state?.catalog?.text;
  const [sortingIsOpen, setSortingIsOpen] = useState(false);

  return { title, sortingIsOpen, setSortingIsOpen };
};

export default useCatalogIdPage;
