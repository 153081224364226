import { useLocation } from "react-router-dom";

const useProductPage = () => {
  const { state } = useLocation();

  const title = state?.product?.title;

  return { title, product: state?.product };
};

export default useProductPage;
