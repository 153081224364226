import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useCatalogBrandPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const title = "LYNX Laboratories";
  const [show, setShow] = useState<"catalog" | "events">("catalog");
  const [sortingIsOpen, setSortingIsOpen] = useState(false);

  const onChangeShow = (type: "catalog" | "events") => {
    setShow(type);
  };

  return {
    navigate,
    title,
    sortingIsOpen,
    setSortingIsOpen,
    show,
    onChangeShow,
    location,
  };
};

export default useCatalogBrandPage;
