import { CSSProperties } from "react";

import Typography from "components/Typography";

import "./styles.scss";

type TextAreaProps = {
  label?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  disabled?: boolean;
};

const TextArea: React.FC<TextAreaProps> = ({
  label,
  placeholder,
  className,
  required,
  onChange,
  value,
  style,
  disabled,
}) => {
  return (
    <div className={`textarea ${className}`} style={style}>
      {label && (
        <Typography
          className={`textarea__label ${
            required ? "textarea__label_required" : ""
          }`}
        >
          {label}
        </Typography>
      )}
      <div className="textarea__area">
        <textarea
          className="textarea__text-area"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TextArea;
