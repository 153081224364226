import Title from "components/Title";
import Header from "components/Header";
import Crumbs from "components/Crumbs";
import Footer from "components/Footer";
import Typography from "components/Typography";

const ShippingPage = () => {
  const title = "Условия доставки";
  return (
    <>
      <Title>{title}</Title>

      <Header />

      <section className="section-wrapper">
        <div className="wrapper">
          <div className="pageHead pageHead_smMg">
            <Crumbs path={title} />

            <Typography variant={"h3"}>{title}</Typography>
          </div>

          <div className="footnote textBlock textBlock_footnote">
            <p>
              Lorem, ipsum dolor, sit amet consectetur adipisicing elit.
              Repellat, id, impedit! Blanditiis, atque accusantium ducimus
              dolores inventore enim fugit sint est, saepe. Quis a consequatur
              quas dolore eaque similique, nam.
            </p>
            <p>
              Lorem, ipsum dolor, sit amet consectetur adipisicing elit.
              Repellat, id, impedit! Blanditiis, atque accusantium ducimus
              dolores inventore enim fugit sint est, saepe. Quis a consequatur
              quas dolore eaque similique, nam.
            </p>
            <p>
              Lorem, ipsum dolor, sit amet consectetur adipisicing elit.
              Repellat, id, impedit! Blanditiis, atque accusantium ducimus
              dolores inventore enim fugit sint est, saepe. Quis a consequatur
              quas dolore eaque similique, nam.
            </p>
            <p>
              Lorem, ipsum dolor, sit amet consectetur adipisicing elit.
              Repellat, id, impedit! Blanditiis, atque accusantium ducimus
              dolores inventore enim fugit sint est, saepe. Quis a consequatur
              quas dolore eaque similique, nam.
            </p>
            <p>
              Lorem, ipsum dolor, sit amet consectetur adipisicing elit.
              Repellat, id, impedit! Blanditiis, atque accusantium ducimus
              dolores inventore enim fugit sint est, saepe. Quis a consequatur
              quas dolore eaque similique, nam.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ShippingPage;
