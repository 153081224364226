import { Link } from "react-router-dom";

import { paths } from "constants/paths";

import Typography from "components/Typography";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

import "./styles.scss";

type ListProps = {
  data: { id: number; text: string }[];
};

const List: React.FC<ListProps> = ({ data }) => {
  return (
    <ul className="list">
      {data?.map((el) => (
        <li key={el.id}>
          <Link
            to={`${paths.catalog}/${el.id}`}
            className="list__item"
            state={{ catalog: el }}
          >
            <Typography variant="subtitle1">{el.text}</Typography>
            <ArrowIcon />
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default List;
