import { useState } from "react";
import { Link } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import Slider from "components/Slider";

import { ReactComponent as MainAdvan1Icon } from "assets/icons/main-advan-icon1.svg";
import { ReactComponent as MainAdvan2Icon } from "assets/icons/main-advan-icon2.svg";
import { ReactComponent as MainAdvan3Icon } from "assets/icons/main-advan-icon3.svg";
import { ReactComponent as MainAdvan4Icon } from "assets/icons/main-advan-icon4.svg";
import { ReactComponent as FaqArrowIcon } from "assets/icons/faq-arrow.svg";

import { faqData } from "./data/faq";
import { sliderData } from "./data/slider";

import "./styles.scss";
import Button from "components/Button";
import { paths } from "constants/paths";

const HomePage = () => {
  const [activeFaq, setActiveFaq] = useState(0);

  const onClickFaq = (id: number) => {
    if (id === activeFaq) {
      setActiveFaq(0);
    } else {
      setActiveFaq(id);
    }
  };

  return (
    <>
      <Header />

      <section className="mainBanner">
        <div className="wrapper">
          <div className="mainBanner__content">
            <h1 className="h3 mainBanner__title">
              <strong>COSMETIC</strong>ZONE <br />
              <strong>ваш надёжный партнёр</strong>
            </h1>
            <p className="subhead mainBanner__subtitle">
              Присоединяйтесь и получите доступ к лучшим, проверенным
              косметологическим средствам от ведущих производителей
            </p>

            <Button to={paths.signUp}>Присоединиться</Button>
          </div>
        </div>
      </section>

      <section className="section section_noPadT">
        <div className="wrapper">
          <div className="mainAdvanWrap">
            <div className="mainAdvan">
              <div className="mainAdvan__icon">
                <MainAdvan1Icon />
              </div>

              <p className="footnote mainAdvan__text">
                Удобная мобильная версия
              </p>
            </div>

            <div className="mainAdvan">
              <div className="mainAdvan__icon">
                <MainAdvan2Icon />
              </div>

              <p className="footnote mainAdvan__text">
                События и мероприятия на одной платформе
              </p>
            </div>

            <div className="mainAdvan">
              <div className="mainAdvan__icon">
                <MainAdvan3Icon />
              </div>

              <p className="footnote mainAdvan__text">
                Официальные поставщики и производители
              </p>
            </div>

            <div className="mainAdvan">
              <div className="mainAdvan__icon">
                <MainAdvan4Icon />
              </div>

              <p className="footnote mainAdvan__text">
                Доступ при наличии медицинского образования
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section overflowH">
        <div className="wrapper">
          <Slider data={sliderData} />
        </div>
      </section>

      <section className="section">
        <div className="wrapper">
          <div className="sectionHead">
            <h2 className="h3 sectionHead__title">
              <strong>О нас</strong>
            </h2>
          </div>

          <div className="subhead mainAbout textBlock textBlock_subhead">
            <p>
              Мы создали удобную платформу для производителей и косметологов.
            </p>
            <p>
              Мы обеспечиваем быструю и удобную доставку продукции от
              производителя к косметологу на всей территории России и стран
              ЕАЭС.
            </p>
            <p>
              На нашей платформе представлены только производители
              косметологических товаров и проверенные официальные представители
              зарубежных производителей.
            </p>
            <p>
              Календарь событий - не пропусти ни одного важного мероприятия.
            </p>
          </div>
        </div>
      </section>

      <section className="mainAccess">
        <div className="wrapper">
          <h2 className="h3 mainAccess__title">
            <strong>Получите доступ</strong>
          </h2>

          <div className="mainAccessItem">
            <p className="mainAccessItem__num">1</p>

            <div className="mainAccessItem__content">
              <p className="subheadM mainAccessItem__title">
                <Link to={"#"}>Регистрация</Link>
                {/* <a href="#">Регистрация</a> */}
              </p>

              <p className="footnote textGray mainAccessItem__text">
                Введите данные и&nbsp;прикрепите документы
              </p>
            </div>
          </div>

          <div className="mainAccessItem">
            <p className="mainAccessItem__num">2</p>

            <div className="mainAccessItem__content">
              <p className="subheadM mainAccessItem__title">Проверка</p>

              <p className="footnote textGray mainAccessItem__text">
                Верификация документов нашими сотрудниками
              </p>
            </div>
          </div>

          <div className="mainAccessItem">
            <p className="mainAccessItem__num">3</p>

            <div className="mainAccessItem__content">
              <p className="subheadM mainAccessItem__title">Готово</p>

              <p className="footnote textGray mainAccessItem__text">
                Вы получили доступ к&nbsp;платформе
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="wrapper">
          <div className="sectionHead">
            <h2 className="h3 sectionHead__title">
              <strong>FAQ</strong>
            </h2>
          </div>

          {faqData.map((el) => (
            <div
              className={`faqItem ${activeFaq === el.id ? "active" : ""}`}
              key={el.id}
              onClick={() => {
                onClickFaq(el.id);
              }}
            >
              <div className="faqItem__head">
                <p className="subheadM faqItem__title">{el.title}</p>

                <button
                  type="button"
                  className="faqItem__arrow"
                  title="Подробнее"
                >
                  <FaqArrowIcon />
                </button>
              </div>

              <div
                className={`footnote faqItem__text textBlock textBlock_footnote ${
                  activeFaq === el.id ? "active" : ""
                }`}
              >
                <p>{el.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HomePage;
