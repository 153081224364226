import Header from "components/Header";
import Title from "components/Title";

import { ReactComponent as SendIcon } from "assets/icons/send.svg";

import "./styles.scss";

const SupportPage = () => {
  const title = "Поддержка";

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header goBack title={title} />

        <div className="support">
          <div className="support__dialog"></div>

          <div className="support__text-input">
            <input
              type="text"
              className="support__input"
              placeholder="Введите сообщение"
            />
            <div className="support__input__send">
              <SendIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportPage;
