import { ReactComponent as AttachIcon } from "assets/icons/attach.svg";
import Button from "components/Button";
import File from "components/File";
import Typography from "components/Typography";
import { useUploadServiceCreate } from "openapi/postman/queries";
import React, { useRef } from "react";
import "./FileUploader.scss";
import { FieldError } from "react-hook-form";

export type UploadedFile = { id: number; name: string };

interface FileUploaderProps {
  title?: string;
  description?: string;
  comment: string;
  buttonLabel?: string; // Новый проп для лейбла над кнопкой
  multiple?: boolean;
  state: UploadedFile[];
  setState: React.Dispatch<React.SetStateAction<UploadedFile[]>>;
  error?: FieldError | undefined;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  title,
  description,
  comment,
  buttonLabel,
  multiple = true,
  state,
  setState,
  error,
}) => {
  const uploadFileRef = useRef<HTMLInputElement | null>(null);

  const { mutateAsync: uploadFiles, isPending } = useUploadServiceCreate();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files?.[0];
    if (files) {
      if (!multiple) {
        setState([]);
      }

      try {
        const response = await uploadFiles({
          //@ts-ignore
          formData: { files: files },
        });

        if (response) {
          // Обновляем state с новыми файлами
          setState((prev) => [
            ...(!multiple ? [] : prev),
            ...(response.map(({ id, url }) => ({
              id: id!,
              name: url?.split("/").pop() || "Файл",
            })) || []),
          ]);
        }
      } catch (error) {
        console.error("Ошибка загрузки файлов:", error);
      }
    }
  };

  const handleFileRemove = (fileId: number) => {
    setState((prev) => prev.filter((file) => file.id !== fileId));
  };

  return (
    <div className="file-uploader">
      {title && <Typography variant="subtitle1">{title}</Typography>}
      {description && (
        <Typography style={{ fontSize: 14 }}>{description}</Typography>
      )}

      <ul className="file-uploader__list">
        {state.map((file) => (
          <File key={file.id} file={file} onDelete={handleFileRemove} />
        ))}
      </ul>

      {buttonLabel && (
        <Typography
          className="file-uploader__button-label file-uploader_required"
          style={{ marginBottom: 4 }}
        >
          {buttonLabel}
        </Typography>
      )}

      <input
        type="file"
        accept=".pdf,.doc,.docx"
        multiple={multiple}
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="file-upload"
        ref={uploadFileRef}
      />
      <label htmlFor="file-upload">
        <Button
          variant="second"
          onClick={() => uploadFileRef.current?.click()}
          startIcon={<AttachIcon />}
        >
          Добавить
        </Button>
      </label>
      {error && (
        <Typography style={{ color: "red" }}>{error.message}</Typography>
      )}
      {comment && (
        <Typography style={{ fontSize: 12, color: "#888" }}>
          {comment}
        </Typography>
      )}
    </div>
  );
};

export default FileUploader;
