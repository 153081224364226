import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useHeader = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const toggleIsOpen = () => {
    setIsOpen((prev) => {
      if (!prev) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }

      return !prev;
    });
  };

  const menuStyle = {
    height: isOpen ? "100%" : 0,
    transition: "720ms",
  };

  const onClose = () => {
    setIsOpen(false);
    menuStyle.transition = "0ms";
  };

  return {
    isOpen,
    navigate,
    menuStyle,
    toggleIsOpen,
    onClose,
  };
};

export default useHeader;
