import { useState } from "react";

const useEventsPage = () => {
  const title = "События";
  const [sortingIsOpen, setSortingIsOpen] = useState(false);

  return { title, sortingIsOpen, setSortingIsOpen };
};

export default useEventsPage;
