import Typography from "components/Typography";

import "./styles.scss";

const AddOptions = () => {
  return (
    <div className="add-options">
      <div className="add-option__wrapper">
        <Typography>Кол-во</Typography>
        <div className="add-option">
          <input type="text" value={1} />
          <Typography variant="subtitle2" className={"add-option__input-value"}>
            шт
          </Typography>
        </div>
      </div>
      <div className="add-option__wrapper">
        <Typography>Объем</Typography>
        <div className="add-option">
          <input type="text" value={0} />
          <Typography className={"add-option__input-value"} variant="subtitle2">
            {""}
          </Typography>
        </div>
      </div>
      <div className="add-option-switch">
        <button className="add-option-switch__button add-option-switch__button_active">
          г
        </button>
        <button className="add-option-switch__button">мл</button>
      </div>
    </div>
  );
};

export default AddOptions;
