import { useLocation, useNavigate } from "react-router-dom";

import Button from "components/Button";
import Header from "components/Header";
import Input from "components/Input";
import Title from "components/Title";
import Typography from "components/Typography";

import FileUploader, {
  UploadedFile,
} from "components/FileUploader/FileUploader";
import { EMAIL_REGEX } from "constants/form";
import { paths } from "constants/paths";
import { useAuthServiceRegisterClinic } from "openapi/postman/queries";
import { RegisterClinicData } from "openapi/postman/requests";
import { useCityServiceGetCities } from "openapi/swagger/queries";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "./styles.scss";
import Select, { Option } from "components/Select/Select";

type SignUpFields = NonNullable<RegisterClinicData["requestBody"]> & {
  confirm_password: string;
};

const SignUpClinicPage = () => {
  const { state } = useLocation();

  const [certificates, setCertificates] = useState<UploadedFile[]>([]);

  const title = `Регистрация ${state?.signUp?.title}`;

  const navigate = useNavigate();

  const { data: citiesRaw } = useCityServiceGetCities();

  const { control, handleSubmit, setError, watch, formState, clearErrors } =
    useForm<SignUpFields>();

  const { mutateAsync } = useAuthServiceRegisterClinic();

  const handleSignup = async ({ confirm_password, ...data }: SignUpFields) => {
    if (!certificates.length) {
      setError("settings.certificate", {
        type: "required",
        message: "Поле обязательно",
      });
      return;
    }
    const [certificate] = certificates;
    try {
      await mutateAsync({
        requestBody: {
          ...data,
          settings: {
            certificate: [certificate.id],
            company: data.settings?.company,
          },
        },
      });
      navigate(paths.account);
    } catch (error) {
      //@ts-ignore
      if (error.status === 400) {
        setError("email", { message: "Почта занята" });
      }
    }
  };

  const cities: Option[] | undefined = citiesRaw?.data?.map((city) => ({
    value: city.id!,
    label: city.name,
  }));

  return (
    <div className="wrapper">
      <Title>{title}</Title>

      <Header goBack title="Регистрация" />

      <div className="sign-up">
        <Typography variant="h3" className="sign-up__title">
          {state?.signUp?.title}
        </Typography>

        <Controller
          name="name"
          control={control}
          rules={{
            required: "Поле обязательно",
          }}
          render={({ field, fieldState }) => (
            <Input
              required
              {...field}
              {...fieldState}
              label="ФИО ответственного"
              placeholder="Иванов Иван Иванович"
              className="sign-up__input"
              type={"email"}
              autoComplete="name"
            />
          )}
        />

        <Controller
          name="settings.company"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              {...fieldState}
              label="Название компании"
              placeholder="Компания"
              className="sign-up__input"
              type={"email"}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{
            required: "Поле обязательно",
            pattern: {
              message: "Неверная почта",
              value: EMAIL_REGEX,
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              required
              {...field}
              {...fieldState}
              label="E-mail"
              placeholder="userexample@mail.ru"
              className="sign-up__input"
              type={"email"}
              autoComplete="email"
            />
          )}
        />

        {/* TODO добавить маску */}
        <Controller
          name="phone"
          control={control}
          rules={{
            required: "Поле обязательно",
          }}
          render={({ field, fieldState }) => (
            <Input
              required
              {...field}
              {...fieldState}
              label="Номер телефона"
              placeholder="+7 952 325 96 70"
              className="sign-up__input"
              type="phone"
            />
          )}
        />

        <Controller
          name="city"
          control={control}
          rules={{
            required: "Поле обязательно",
          }}
          render={({ field, fieldState, formState }) => (
            <Select
              required
              label="Ваш город"
              placeholder="Выберите город"
              options={cities}
              onChange={field.onChange}
              {...fieldState}
            />
          )}
        />

        <FileUploader
          title="Сертификация"
          description="Для регистрации, необходимо прикрепить документы (скан или фото), подтверждающие вашу личность и специальное образование"
          comment="Подтверждение, что вы имеете специализацию в сфере клиническая косметология"
          buttonLabel="Сертификат"
          state={certificates}
          setState={(certificates) => {
            clearErrors("settings.certificate");
            setCertificates(certificates);
          }}
          multiple={false}
          // @ts-ignore
          error={formState.errors.settings?.certificate}
        />

        <Typography variant="subtitle1" style={{ margin: "24px 0 16px" }}>
          Безопасность
        </Typography>

        <Controller
          name="password"
          control={control}
          rules={{
            required: "Поле обязательно",
          }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              {...fieldState}
              label="Пароль"
              placeholder="Введите пароль"
              className="sign-up__input"
              type="password"
              autoComplete="new-password"
              required
            />
          )}
        />

        <Controller
          name="confirm_password"
          control={control}
          rules={{
            required: "Поле обязательно",
            validate: (val: string) => {
              if (watch("password") !== val) {
                return "Пароли не совпадают";
              }
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              {...fieldState}
              label="Повторите пароль"
              placeholder="Введите пароль"
              className="sign-up__input"
              type="password"
              autoComplete="new-password"
              required
            />
          )}
        />

        <Typography className="sign-up__agreement">
          Нажимая на кнопку "Зарегистрироваться" вы даёте своё соглашение на
          обработку персональных данных в соответствии{" "}
          <span>Пользовательским соглашением</span>
        </Typography>

        <Button full size="large" onClick={handleSubmit(handleSignup)}>
          Зарегистрироваться
        </Button>
      </div>
    </div>
  );
};

export default SignUpClinicPage;
