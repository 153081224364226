import Title from "components/Title";
import Header from "components/Header";
import Crumbs from "components/Crumbs";
import Typography from "components/Typography";
import Footer from "components/Footer";
import Button from "components/Button";
import Pagination from "components/Pagination";
import Sorting from "components/Sorting";
import Events from "components/Events";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as FilterIcon } from "assets/icons/filter-icon.svg";

import useEventsPage from "./useEventsPage";

import "./styles.scss";

type EventsPageProps = {
  edit?: boolean;
};

const EventsPage: React.FC<EventsPageProps> = ({ edit }) => {
  const { title, sortingIsOpen, setSortingIsOpen } = useEventsPage();

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header />

        <div className="catalog-id">
          <Crumbs path={title} />
          <Typography variant="h3">{title}</Typography>

          <div className="catalog-id__filter">
            <button
              className="catalog-id__filter__button"
              onClick={() => {
                setSortingIsOpen(true);
              }}
            >
              <Typography
                variant="subtitle2"
                className="catalog-id__filter__button__text"
              >
                По дате проведения
              </Typography>
              <ArrowIcon
                className={
                  "catalog-id__filter__button__icon catalog-id__filter__button__icon_arrow " +
                  (!!sortingIsOpen
                    ? "catalog-id__filter__button__icon_arrow-open"
                    : "")
                }
              />
            </button>
            <button className="catalog-id__filter__button" onClick={() => {}}>
              <FilterIcon className="catalog-id__filter__button__icon catalog-id__filter__button__icon_filter" />
              <Typography
                variant="subtitle2"
                className="catalog-id__filter__button__text"
              >
                Фильтры
              </Typography>
            </button>
          </div>

          <Typography className="catalog-id__result" variant="body1">
            Найдено 56 событий
          </Typography>
          <Events edit={edit} />
        </div>

        <Button
          full
          size="medium"
          variant="outlined"
          style={{ marginBottom: 16 }}
        >
          Показать еще
        </Button>

        <Pagination />
      </div>

      <Sorting isOpen={sortingIsOpen} setOpen={setSortingIsOpen} />

      <Footer />
    </>
  );
};

export default EventsPage;
