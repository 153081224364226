// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AccessLevelService, BrandService, CityService, EventCategoryService, EventService, ProductService, StatusService, UserClinicService, UsersPermissionsAuthService, UsersPermissionsUsersRolesService } from "../requests/services.gen";
import { AccessLevelRequest, BrandRequest, CityRequest, EventCategoryRequest, EventRequest, ProductRequest, StatusRequest, UserClinicRequest, Users_Permissions_PermissionsTree } from "../requests/types.gen";
import * as Common from "./common";
/**
* @param data The data for the request.
* @param data.sort Sort by attributes ascending (asc) or descending (desc)
* @param data.paginationWithCount Return page/pageSize (default: true)
* @param data.paginationPage Page number (default: 0)
* @param data.paginationPageSize Page size (default: 25)
* @param data.paginationStart Offset value (default: 0)
* @param data.paginationLimit Number of entities to return (default: 25)
* @param data.fields Fields to return (ex: title,author)
* @param data.populate Relations to return
* @param data.filters Filters to apply
* @param data.locale Locale to apply
* @returns AccessLevelListResponse OK
* @throws ApiError
*/
export const useAccessLevelServiceGetAccessLevels = <TData = Common.AccessLevelServiceGetAccessLevelsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccessLevelServiceGetAccessLevelsKeyFn({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }, queryKey), queryFn: () => AccessLevelService.getAccessLevels({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns AccessLevelResponse OK
* @throws ApiError
*/
export const useAccessLevelServiceGetAccessLevelsId = <TData = Common.AccessLevelServiceGetAccessLevelsIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccessLevelServiceGetAccessLevelsIdKeyFn({ id }, queryKey), queryFn: () => AccessLevelService.getAccessLevelsId({ id }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.sort Sort by attributes ascending (asc) or descending (desc)
* @param data.paginationWithCount Return page/pageSize (default: true)
* @param data.paginationPage Page number (default: 0)
* @param data.paginationPageSize Page size (default: 25)
* @param data.paginationStart Offset value (default: 0)
* @param data.paginationLimit Number of entities to return (default: 25)
* @param data.fields Fields to return (ex: title,author)
* @param data.populate Relations to return
* @param data.filters Filters to apply
* @param data.locale Locale to apply
* @returns BrandListResponse OK
* @throws ApiError
*/
export const useBrandServiceGetBrands = <TData = Common.BrandServiceGetBrandsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBrandServiceGetBrandsKeyFn({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }, queryKey), queryFn: () => BrandService.getBrands({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns BrandResponse OK
* @throws ApiError
*/
export const useBrandServiceGetBrandsId = <TData = Common.BrandServiceGetBrandsIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseBrandServiceGetBrandsIdKeyFn({ id }, queryKey), queryFn: () => BrandService.getBrandsId({ id }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.sort Sort by attributes ascending (asc) or descending (desc)
* @param data.paginationWithCount Return page/pageSize (default: true)
* @param data.paginationPage Page number (default: 0)
* @param data.paginationPageSize Page size (default: 25)
* @param data.paginationStart Offset value (default: 0)
* @param data.paginationLimit Number of entities to return (default: 25)
* @param data.fields Fields to return (ex: title,author)
* @param data.populate Relations to return
* @param data.filters Filters to apply
* @param data.locale Locale to apply
* @returns CityListResponse OK
* @throws ApiError
*/
export const useCityServiceGetCities = <TData = Common.CityServiceGetCitiesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCityServiceGetCitiesKeyFn({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }, queryKey), queryFn: () => CityService.getCities({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns CityResponse OK
* @throws ApiError
*/
export const useCityServiceGetCitiesId = <TData = Common.CityServiceGetCitiesIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCityServiceGetCitiesIdKeyFn({ id }, queryKey), queryFn: () => CityService.getCitiesId({ id }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.sort Sort by attributes ascending (asc) or descending (desc)
* @param data.paginationWithCount Return page/pageSize (default: true)
* @param data.paginationPage Page number (default: 0)
* @param data.paginationPageSize Page size (default: 25)
* @param data.paginationStart Offset value (default: 0)
* @param data.paginationLimit Number of entities to return (default: 25)
* @param data.fields Fields to return (ex: title,author)
* @param data.populate Relations to return
* @param data.filters Filters to apply
* @param data.locale Locale to apply
* @returns EventListResponse OK
* @throws ApiError
*/
export const useEventServiceGetEvents = <TData = Common.EventServiceGetEventsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEventServiceGetEventsKeyFn({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }, queryKey), queryFn: () => EventService.getEvents({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns EventResponse OK
* @throws ApiError
*/
export const useEventServiceGetEventsId = <TData = Common.EventServiceGetEventsIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEventServiceGetEventsIdKeyFn({ id }, queryKey), queryFn: () => EventService.getEventsId({ id }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.sort Sort by attributes ascending (asc) or descending (desc)
* @param data.paginationWithCount Return page/pageSize (default: true)
* @param data.paginationPage Page number (default: 0)
* @param data.paginationPageSize Page size (default: 25)
* @param data.paginationStart Offset value (default: 0)
* @param data.paginationLimit Number of entities to return (default: 25)
* @param data.fields Fields to return (ex: title,author)
* @param data.populate Relations to return
* @param data.filters Filters to apply
* @param data.locale Locale to apply
* @returns EventCategoryListResponse OK
* @throws ApiError
*/
export const useEventCategoryServiceGetEventCategories = <TData = Common.EventCategoryServiceGetEventCategoriesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEventCategoryServiceGetEventCategoriesKeyFn({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }, queryKey), queryFn: () => EventCategoryService.getEventCategories({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns EventCategoryResponse OK
* @throws ApiError
*/
export const useEventCategoryServiceGetEventCategoriesId = <TData = Common.EventCategoryServiceGetEventCategoriesIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseEventCategoryServiceGetEventCategoriesIdKeyFn({ id }, queryKey), queryFn: () => EventCategoryService.getEventCategoriesId({ id }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.sort Sort by attributes ascending (asc) or descending (desc)
* @param data.paginationWithCount Return page/pageSize (default: true)
* @param data.paginationPage Page number (default: 0)
* @param data.paginationPageSize Page size (default: 25)
* @param data.paginationStart Offset value (default: 0)
* @param data.paginationLimit Number of entities to return (default: 25)
* @param data.fields Fields to return (ex: title,author)
* @param data.populate Relations to return
* @param data.filters Filters to apply
* @param data.locale Locale to apply
* @returns ProductListResponse OK
* @throws ApiError
*/
export const useProductServiceGetProducts = <TData = Common.ProductServiceGetProductsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseProductServiceGetProductsKeyFn({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }, queryKey), queryFn: () => ProductService.getProducts({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns ProductResponse OK
* @throws ApiError
*/
export const useProductServiceGetProductsId = <TData = Common.ProductServiceGetProductsIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseProductServiceGetProductsIdKeyFn({ id }, queryKey), queryFn: () => ProductService.getProductsId({ id }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.sort Sort by attributes ascending (asc) or descending (desc)
* @param data.paginationWithCount Return page/pageSize (default: true)
* @param data.paginationPage Page number (default: 0)
* @param data.paginationPageSize Page size (default: 25)
* @param data.paginationStart Offset value (default: 0)
* @param data.paginationLimit Number of entities to return (default: 25)
* @param data.fields Fields to return (ex: title,author)
* @param data.populate Relations to return
* @param data.filters Filters to apply
* @param data.locale Locale to apply
* @returns StatusListResponse OK
* @throws ApiError
*/
export const useStatusServiceGetStatuses = <TData = Common.StatusServiceGetStatusesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatusServiceGetStatusesKeyFn({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }, queryKey), queryFn: () => StatusService.getStatuses({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }) as TData, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns StatusResponse OK
* @throws ApiError
*/
export const useStatusServiceGetStatusesId = <TData = Common.StatusServiceGetStatusesIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStatusServiceGetStatusesIdKeyFn({ id }, queryKey), queryFn: () => StatusService.getStatusesId({ id }) as TData, ...options });
/**
* Login with a provider
* Redirects to provider login before being redirect to /auth/{provider}/callback
* @param data The data for the request.
* @param data.provider Provider name
* @returns Error Error
* @throws ApiError
*/
export const useUsersPermissionsAuthServiceGetConnectByProvider = <TData = Common.UsersPermissionsAuthServiceGetConnectByProviderDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ provider }: {
  provider: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsAuthServiceGetConnectByProviderKeyFn({ provider }, queryKey), queryFn: () => UsersPermissionsAuthService.getConnectByProvider({ provider }) as TData, ...options });
/**
* Default Callback from provider auth
* @param data The data for the request.
* @param data.provider Provider name
* @returns Users_Permissions_UserRegistration Returns a jwt token and user info
* @throws ApiError
*/
export const useUsersPermissionsAuthServiceGetAuthByProviderCallback = <TData = Common.UsersPermissionsAuthServiceGetAuthByProviderCallbackDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ provider }: {
  provider: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsAuthServiceGetAuthByProviderCallbackKeyFn({ provider }, queryKey), queryFn: () => UsersPermissionsAuthService.getAuthByProviderCallback({ provider }) as TData, ...options });
/**
* Confirm user email
* @param data The data for the request.
* @param data.confirmation confirmation token received by email
* @returns Error Error
* @throws ApiError
*/
export const useUsersPermissionsAuthServiceGetAuthEmailConfirmation = <TData = Common.UsersPermissionsAuthServiceGetAuthEmailConfirmationDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ confirmation }: {
  confirmation?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsAuthServiceGetAuthEmailConfirmationKeyFn({ confirmation }, queryKey), queryFn: () => UsersPermissionsAuthService.getAuthEmailConfirmation({ confirmation }) as TData, ...options });
/**
* Get default generated permissions
* @returns unknown Returns the permissions tree
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServiceGetUsersPermissionsPermissions = <TData = Common.UsersPermissionsUsersRolesServiceGetUsersPermissionsPermissionsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsUsersRolesServiceGetUsersPermissionsPermissionsKeyFn(queryKey), queryFn: () => UsersPermissionsUsersRolesService.getUsersPermissionsPermissions() as TData, ...options });
/**
* List roles
* @returns unknown Returns list of roles
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServiceGetUsersPermissionsRoles = <TData = Common.UsersPermissionsUsersRolesServiceGetUsersPermissionsRolesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsUsersRolesServiceGetUsersPermissionsRolesKeyFn(queryKey), queryFn: () => UsersPermissionsUsersRolesService.getUsersPermissionsRoles() as TData, ...options });
/**
* Get a role
* @param data The data for the request.
* @param data.id role Id
* @returns unknown Returns the role
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServiceGetUsersPermissionsRolesById = <TData = Common.UsersPermissionsUsersRolesServiceGetUsersPermissionsRolesByIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsUsersRolesServiceGetUsersPermissionsRolesByIdKeyFn({ id }, queryKey), queryFn: () => UsersPermissionsUsersRolesService.getUsersPermissionsRolesById({ id }) as TData, ...options });
/**
* Get list of users
* @returns Users_Permissions_User Returns an array of users
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServiceGetUsers = <TData = Common.UsersPermissionsUsersRolesServiceGetUsersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsUsersRolesServiceGetUsersKeyFn(queryKey), queryFn: () => UsersPermissionsUsersRolesService.getUsers() as TData, ...options });
/**
* Get a user
* @param data The data for the request.
* @param data.id user Id
* @returns Users_Permissions_User Returns a user
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServiceGetUsersById = <TData = Common.UsersPermissionsUsersRolesServiceGetUsersByIdDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsUsersRolesServiceGetUsersByIdKeyFn({ id }, queryKey), queryFn: () => UsersPermissionsUsersRolesService.getUsersById({ id }) as TData, ...options });
/**
* Get authenticated user info
* @returns Users_Permissions_User Returns user info
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServiceGetUsersMe = <TData = Common.UsersPermissionsUsersRolesServiceGetUsersMeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsUsersRolesServiceGetUsersMeKeyFn(queryKey), queryFn: () => UsersPermissionsUsersRolesService.getUsersMe() as TData, ...options });
/**
* Get user count
* @returns number Returns a number
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServiceGetUsersCount = <TData = Common.UsersPermissionsUsersRolesServiceGetUsersCountDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersPermissionsUsersRolesServiceGetUsersCountKeyFn(queryKey), queryFn: () => UsersPermissionsUsersRolesService.getUsersCount() as TData, ...options });
/**
* @param data The data for the request.
* @param data.requestBody
* @returns AccessLevelResponse OK
* @throws ApiError
*/
export const useAccessLevelServicePostAccessLevels = <TData = Common.AccessLevelServicePostAccessLevelsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: AccessLevelRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: AccessLevelRequest;
}, TContext>({ mutationFn: ({ requestBody }) => AccessLevelService.postAccessLevels({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.requestBody
* @returns BrandResponse OK
* @throws ApiError
*/
export const useBrandServicePostBrands = <TData = Common.BrandServicePostBrandsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: BrandRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: BrandRequest;
}, TContext>({ mutationFn: ({ requestBody }) => BrandService.postBrands({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.requestBody
* @returns CityResponse OK
* @throws ApiError
*/
export const useCityServicePostCities = <TData = Common.CityServicePostCitiesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CityRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CityRequest;
}, TContext>({ mutationFn: ({ requestBody }) => CityService.postCities({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.requestBody
* @returns EventResponse OK
* @throws ApiError
*/
export const useEventServicePostEvents = <TData = Common.EventServicePostEventsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: EventRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: EventRequest;
}, TContext>({ mutationFn: ({ requestBody }) => EventService.postEvents({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.requestBody
* @returns EventCategoryResponse OK
* @throws ApiError
*/
export const useEventCategoryServicePostEventCategories = <TData = Common.EventCategoryServicePostEventCategoriesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: EventCategoryRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: EventCategoryRequest;
}, TContext>({ mutationFn: ({ requestBody }) => EventCategoryService.postEventCategories({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.requestBody
* @returns ProductResponse OK
* @throws ApiError
*/
export const useProductServicePostProducts = <TData = Common.ProductServicePostProductsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ProductRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ProductRequest;
}, TContext>({ mutationFn: ({ requestBody }) => ProductService.postProducts({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.requestBody
* @returns StatusResponse OK
* @throws ApiError
*/
export const useStatusServicePostStatuses = <TData = Common.StatusServicePostStatusesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: StatusRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: StatusRequest;
}, TContext>({ mutationFn: ({ requestBody }) => StatusService.postStatuses({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.requestBody
* @returns UserClinicResponse OK
* @throws ApiError
*/
export const useUserClinicServicePostUsersClinic = <TData = Common.UserClinicServicePostUsersClinicMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: UserClinicRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: UserClinicRequest;
}, TContext>({ mutationFn: ({ requestBody }) => UserClinicService.postUsersClinic({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Local login
* Returns a jwt token and user info
* @param data The data for the request.
* @param data.requestBody
* @returns Users_Permissions_UserRegistration Connection
* @throws ApiError
*/
export const useUsersPermissionsAuthServicePostAuthLocal = <TData = Common.UsersPermissionsAuthServicePostAuthLocalMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { identifier?: string; password?: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { identifier?: string; password?: string; };
}, TContext>({ mutationFn: ({ requestBody }) => UsersPermissionsAuthService.postAuthLocal({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Register a user
* Returns a jwt token and user info
* @param data The data for the request.
* @param data.requestBody
* @returns Users_Permissions_UserRegistration Successful registration
* @throws ApiError
*/
export const useUsersPermissionsAuthServicePostAuthLocalRegister = <TData = Common.UsersPermissionsAuthServicePostAuthLocalRegisterMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { username?: string; email?: string; password?: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { username?: string; email?: string; password?: string; };
}, TContext>({ mutationFn: ({ requestBody }) => UsersPermissionsAuthService.postAuthLocalRegister({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Send rest password email
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Returns ok
* @throws ApiError
*/
export const useUsersPermissionsAuthServicePostAuthForgotPassword = <TData = Common.UsersPermissionsAuthServicePostAuthForgotPasswordMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { email?: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { email?: string; };
}, TContext>({ mutationFn: ({ requestBody }) => UsersPermissionsAuthService.postAuthForgotPassword({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Rest user password
* @param data The data for the request.
* @param data.requestBody
* @returns Users_Permissions_UserRegistration Returns a jwt token and user info
* @throws ApiError
*/
export const useUsersPermissionsAuthServicePostAuthResetPassword = <TData = Common.UsersPermissionsAuthServicePostAuthResetPasswordMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { password?: string; passwordConfirmation?: string; code?: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { password?: string; passwordConfirmation?: string; code?: string; };
}, TContext>({ mutationFn: ({ requestBody }) => UsersPermissionsAuthService.postAuthResetPassword({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Update user's own password
* @param data The data for the request.
* @param data.requestBody
* @returns Users_Permissions_UserRegistration Returns a jwt token and user info
* @throws ApiError
*/
export const useUsersPermissionsAuthServicePostAuthChangePassword = <TData = Common.UsersPermissionsAuthServicePostAuthChangePasswordMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { password: string; currentPassword: string; passwordConfirmation: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { password: string; currentPassword: string; passwordConfirmation: string; };
}, TContext>({ mutationFn: ({ requestBody }) => UsersPermissionsAuthService.postAuthChangePassword({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Send confirmation email
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Returns email and boolean to confirm email was sent
* @throws ApiError
*/
export const useUsersPermissionsAuthServicePostAuthSendEmailConfirmation = <TData = Common.UsersPermissionsAuthServicePostAuthSendEmailConfirmationMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { email?: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { email?: string; };
}, TContext>({ mutationFn: ({ requestBody }) => UsersPermissionsAuthService.postAuthSendEmailConfirmation({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Create a role
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Returns ok if the role was create
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServicePostUsersPermissionsRoles = <TData = Common.UsersPermissionsUsersRolesServicePostUsersPermissionsRolesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name?: string; description?: string; type?: string; permissions?: Users_Permissions_PermissionsTree; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name?: string; description?: string; type?: string; permissions?: Users_Permissions_PermissionsTree; };
}, TContext>({ mutationFn: ({ requestBody }) => UsersPermissionsUsersRolesService.postUsersPermissionsRoles({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* Create a user
* @param data The data for the request.
* @param data.requestBody
* @returns unknown Returns created user info
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServicePostUsers = <TData = Common.UsersPermissionsUsersRolesServicePostUsersMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { email: string; username: string; password: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { email: string; username: string; password: string; };
}, TContext>({ mutationFn: ({ requestBody }) => UsersPermissionsUsersRolesService.postUsers({ requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns AccessLevelResponse OK
* @throws ApiError
*/
export const useAccessLevelServicePutAccessLevelsId = <TData = Common.AccessLevelServicePutAccessLevelsIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: AccessLevelRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: AccessLevelRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => AccessLevelService.putAccessLevelsId({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns BrandResponse OK
* @throws ApiError
*/
export const useBrandServicePutBrandsId = <TData = Common.BrandServicePutBrandsIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: BrandRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: BrandRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => BrandService.putBrandsId({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns CityResponse OK
* @throws ApiError
*/
export const useCityServicePutCitiesId = <TData = Common.CityServicePutCitiesIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: CityRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: CityRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => CityService.putCitiesId({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns EventResponse OK
* @throws ApiError
*/
export const useEventServicePutEventsId = <TData = Common.EventServicePutEventsIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: EventRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: EventRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => EventService.putEventsId({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns EventCategoryResponse OK
* @throws ApiError
*/
export const useEventCategoryServicePutEventCategoriesId = <TData = Common.EventCategoryServicePutEventCategoriesIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: EventCategoryRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: EventCategoryRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => EventCategoryService.putEventCategoriesId({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns ProductResponse OK
* @throws ApiError
*/
export const useProductServicePutProductsId = <TData = Common.ProductServicePutProductsIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: ProductRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: ProductRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => ProductService.putProductsId({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @param data.requestBody
* @returns StatusResponse OK
* @throws ApiError
*/
export const useStatusServicePutStatusesId = <TData = Common.StatusServicePutStatusesIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody: StatusRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody: StatusRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => StatusService.putStatusesId({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* Update a role
* @param data The data for the request.
* @param data.role role Id
* @param data.requestBody
* @returns unknown Returns ok if the role was udpated
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServicePutUsersPermissionsRolesByRole = <TData = Common.UsersPermissionsUsersRolesServicePutUsersPermissionsRolesByRoleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: { name?: string; description?: string; type?: string; permissions?: Users_Permissions_PermissionsTree; };
  role: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: { name?: string; description?: string; type?: string; permissions?: Users_Permissions_PermissionsTree; };
  role: string;
}, TContext>({ mutationFn: ({ requestBody, role }) => UsersPermissionsUsersRolesService.putUsersPermissionsRolesByRole({ requestBody, role }) as unknown as Promise<TData>, ...options });
/**
* Update a user
* @param data The data for the request.
* @param data.id user Id
* @param data.requestBody
* @returns unknown Returns updated user info
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServicePutUsersById = <TData = Common.UsersPermissionsUsersRolesServicePutUsersByIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: { email: string; username: string; password: string; };
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: { email: string; username: string; password: string; };
}, TContext>({ mutationFn: ({ id, requestBody }) => UsersPermissionsUsersRolesService.putUsersById({ id, requestBody }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns number OK
* @throws ApiError
*/
export const useAccessLevelServiceDeleteAccessLevelsId = <TData = Common.AccessLevelServiceDeleteAccessLevelsIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
}, TContext>({ mutationFn: ({ id }) => AccessLevelService.deleteAccessLevelsId({ id }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns number OK
* @throws ApiError
*/
export const useBrandServiceDeleteBrandsId = <TData = Common.BrandServiceDeleteBrandsIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
}, TContext>({ mutationFn: ({ id }) => BrandService.deleteBrandsId({ id }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns number OK
* @throws ApiError
*/
export const useCityServiceDeleteCitiesId = <TData = Common.CityServiceDeleteCitiesIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
}, TContext>({ mutationFn: ({ id }) => CityService.deleteCitiesId({ id }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns number OK
* @throws ApiError
*/
export const useEventServiceDeleteEventsId = <TData = Common.EventServiceDeleteEventsIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
}, TContext>({ mutationFn: ({ id }) => EventService.deleteEventsId({ id }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns number OK
* @throws ApiError
*/
export const useEventCategoryServiceDeleteEventCategoriesId = <TData = Common.EventCategoryServiceDeleteEventCategoriesIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
}, TContext>({ mutationFn: ({ id }) => EventCategoryService.deleteEventCategoriesId({ id }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns number OK
* @throws ApiError
*/
export const useProductServiceDeleteProductsId = <TData = Common.ProductServiceDeleteProductsIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
}, TContext>({ mutationFn: ({ id }) => ProductService.deleteProductsId({ id }) as unknown as Promise<TData>, ...options });
/**
* @param data The data for the request.
* @param data.id
* @returns number OK
* @throws ApiError
*/
export const useStatusServiceDeleteStatusesId = <TData = Common.StatusServiceDeleteStatusesIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
}, TContext>({ mutationFn: ({ id }) => StatusService.deleteStatusesId({ id }) as unknown as Promise<TData>, ...options });
/**
* Delete a role
* @param data The data for the request.
* @param data.role role Id
* @returns unknown Returns ok if the role was delete
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServiceDeleteUsersPermissionsRolesByRole = <TData = Common.UsersPermissionsUsersRolesServiceDeleteUsersPermissionsRolesByRoleMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  role: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  role: string;
}, TContext>({ mutationFn: ({ role }) => UsersPermissionsUsersRolesService.deleteUsersPermissionsRolesByRole({ role }) as unknown as Promise<TData>, ...options });
/**
* Delete a user
* @param data The data for the request.
* @param data.id user Id
* @returns unknown Returns deleted user info
* @throws ApiError
*/
export const useUsersPermissionsUsersRolesServiceDeleteUsersById = <TData = Common.UsersPermissionsUsersRolesServiceDeleteUsersByIdMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => UsersPermissionsUsersRolesService.deleteUsersById({ id }) as unknown as Promise<TData>, ...options });
