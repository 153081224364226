export const paths = {
  any: "*",
  home: "/",
  signIn: "/signin",
  signUp: "/signup",
  signUpClinic: "/signup/clinic",
  signUpCosmetologist: "/signup/cosmetologist",
  forgotPassword: "/forgot-password",
  password: "/password",
  account: "/account",
  accountProfile: "/account/profile",
  accountOrders: "/account/orders",
  accountOrdersId: "/account/orders/:id",
  accountBrands: "/account/brands",
  accountBrandsCreate: "/account/brands/create",
  accountProducts: "/account/products",
  accountProductsAdd: "/account/add",
  accountEvents: "/account/events",
  accountEventsAdd: "/account/events/add",
  cart: "/cart",
  order: "/order",
  about: "/about",
  brands: "/brands",
  contacts: "/contacts",
  licenses: "/licenses",
  policy: "/policy",
  shipping: "/shipping",
  catalog: "/catalog",
  catalogId: "/catalog/:id",
  catalogBrands: "/catalog-brands",
  catalogBrandsId: "/catalog-brands/:id",
  events: "/events",
  eventsId: "/events/:idF",
  product: "/product",
  productId: "/product/:id",
  filters: "/filters",
  support: "/support",
} as const;
