import { eventsData } from "pages/EventsPage/data/events";
import Event from "./Event";
import Button from "components/Button";

import { ReactComponent as AddIcon } from "assets/icons/plus-white.svg";
import { paths } from "constants/paths";

import "./styles.scss";

type EventsProps = {
  edit?: boolean;
};

const Events: React.FC<EventsProps> = ({ edit }) => {
  return (
    <div className="events__list">
      {edit && (
        <Button
          to={paths.accountEventsAdd}
          startIcon={<AddIcon />}
          full
          style={{ marginBottom: 24 }}
        >
          Добавить событие
        </Button>
      )}
      {eventsData.map((el) => (
        <Event key={el.id} {...el} edit={edit} />
      ))}
    </div>
  );
};

export default Events;
