import { Link } from "react-router-dom";

import useHeader from "./useHeader";
import { paths } from "constants/paths";

import Button from "components/Button";
import Search from "components/Search";

import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as BackIcon } from "assets/icons/back-icon.svg";
import { ReactComponent as BurgerIcon } from "assets/icons/burger-icon.svg";
import { ReactComponent as BurgerCloseIcon } from "assets/icons/burger-close.svg";
import { ReactComponent as LoginWhiteIcon } from "assets/icons/login-icon-white.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as CartIcon } from "assets/icons/cart.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

import { ReactComponent as MirIcon } from "assets/icons/mir.svg";
import { ReactComponent as SbpIcon } from "assets/icons/sbp-icon.svg";

import "./styles.scss";

type HeaderProps = {
  goBack?: string | boolean;
  title?: string;
  search?: string;
  onChangeSearch?: (search: string) => void;
};

const Header: React.FC<HeaderProps> = ({
  goBack,
  title,
  search,
  onChangeSearch,
}) => {
  const { isOpen, navigate, menuStyle, toggleIsOpen, onClose } = useHeader();
  return (
    <>
      <header className="header">
        <div className="header__wrap">
          <div
            className="header__left"
            style={{ flexGrow: search !== undefined ? 1 : "auto" }}
          >
            {goBack ? (
              <button
                type="button"
                className="header__icon"
                title="Назад"
                onClick={() => {
                  if (typeof goBack === "string") {
                    navigate(goBack);
                  } else {
                    navigate(-1);
                  }
                }}
              >
                <BackIcon className="header__back-icon" />
              </button>
            ) : (
              <button
                type="button"
                className="header__icon"
                title="Меню"
                onClick={toggleIsOpen}
              >
                {isOpen ? <BurgerCloseIcon /> : <BurgerIcon />}
              </button>
            )}
            {!goBack && (
              <button
                type="button"
                className="header__icon"
                title="Войти"
                style={{ cursor: "default" }}
              />
            )}
            {search !== undefined ? (
              <Search value={search} onChange={onChangeSearch} />
            ) : (
              <span className="header__title">{title}</span>
            )}
          </div>

          {!title && !search && (
            <Link to={paths.home} className="header__logo" onClick={onClose}>
              <Logo />
            </Link>
          )}

          {search === undefined && (
            <div
              className={`header__right`}
              style={{ visibility: goBack ? "hidden" : "visible" }}
            >
              {/* <button
                type="button"
                className="header__icon"
                title="Войти"
                onClick={() => {
                  navigate(paths.login);
                }}
              >
                <LoginDarkIcon />
              </button> */}
              <button
                type="button"
                className="header__icon"
                title="Войти"
                onClick={() => {
                  navigate(paths.account);
                }}
              >
                <UserIcon />
              </button>
              <button
                type="button"
                className="header__icon"
                title="Корзина"
                onClick={() => {
                  navigate(paths.cart);
                }}
              >
                <CartIcon />
              </button>
            </div>
          )}
        </div>
      </header>

      <section className="mobMenu" style={menuStyle}>
        <div className="mobMenu__inner">
          <nav className="mobileMenu">
            <Link
              to={paths.catalog}
              className="mobileMenuItem"
              onClick={onClose}
            >
              <span className="mobileMenuItem__text">
                <strong>Каталог</strong>
              </span>

              <span className="mobileMenuItem__icon">
                <ArrowIcon />
              </span>
            </Link>
            <Link
              to={paths.catalogBrands}
              className="mobileMenuItem"
              onClick={onClose}
            >
              <span className="mobileMenuItem__text">
                <strong>Бренды</strong>
              </span>

              <span className="mobileMenuItem__icon">
                <ArrowIcon />
              </span>
            </Link>

            <Link
              to={paths.events}
              className="mobileMenuItem"
              onClick={onClose}
            >
              <span className="mobileMenuItem__text">
                <strong>События</strong>
              </span>

              <span className="mobileMenuItem__icon">
                <ArrowIcon />
              </span>
            </Link>
          </nav>

          <div className="mobMenuUnavailable">
            <p className="subhead textGray mobMenuUnavailable__title">
              Войдите, чтобы открыть доступ к платформе
            </p>

            <Button
              full
              size={"large"}
              startIcon={<LoginWhiteIcon />}
              to={paths.signUp}
              onClick={onClose}
            >
              Войти
            </Button>
          </div>

          <div className="mobMenu__fixedDown">
            <nav className="mobileCommonMenu">
              <Link
                to={paths.about}
                className="subhead mobileCommonMenu__item"
                onClick={onClose}
              >
                О компании
              </Link>
              <Link
                to={paths.brands}
                className="subhead mobileCommonMenu__item"
                onClick={onClose}
              >
                Бренды
              </Link>
              <Link
                to={paths.policy}
                className="subhead mobileCommonMenu__item"
                onClick={onClose}
              >
                Правовая информация
              </Link>
              <Link
                to={paths.contacts}
                className="subhead mobileCommonMenu__item"
                onClick={onClose}
              >
                Контакты
              </Link>
            </nav>

            <div className="mobMenu__under">
              <div className="mobMenuHelp">
                <p className="footnote mobMenuHelp__title">Нужна помощь?</p>
                <p className="subheadM mobMenuHelp__text">
                  Задать вопрос в поддержку
                </p>
              </div>

              <div className="mobMenu__bottom">
                <p className="caption2 mobMenu__copyright">
                  &copy; 2024 Все права защищены
                </p>

                <div className="mobMenuPayment">
                  <div className="mobMenuPayment__item">
                    <MirIcon />
                  </div>
                  <div className="mobMenuPayment__item">
                    <SbpIcon style={{ height: 18 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
