import { AccordionItem } from "@szhsin/react-accordion";
import File from "components/File";

import { ReactComponent as ArrowIcon } from "assets/icons/faq-arrow.svg";

const ProductProtocols = () => {
  return (
    <AccordionItem
      header={
        <>
          Протоколы
          <ArrowIcon className="chevron-down" />
        </>
      }
    >
      <div className="product-accordion">
        {/* <File fileName="Протокол процедуры NITHYA для лица.pdf" /> */}
      </div>
    </AccordionItem>
  );
};

export default ProductProtocols;
