import { Helmet } from "react-helmet-async";
import { getTitle } from "utils/getTitle";

type TitleProps = {
  children: string;
};

const Title: React.FC<TitleProps> = ({ children }) => {
  return (
    <Helmet>
      <title>{getTitle(children)}</title>
    </Helmet>
  );
};

export default Title;
