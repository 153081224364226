import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "./styles.scss";

type SliderProps = {
  data: { id: number; image: string; title?: string }[];
};

const Slider: React.FC<SliderProps> = ({ data }) => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={16}
        speed={500}
        loop={false}
        pagination={{
          el: ".mainSlider__dots",
          type: "bullets",
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {data?.map((el) => (
          <SwiperSlide key={el.id}>
            <div className="mainSliderItem swiper-slide">
              <div className="mainSliderItem__image">
                <img src={el.image} alt="" />
              </div>

              {el.title && (
                <p className="subheadM mainSliderItem__title">{el.title}</p>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="mainSlider__dots"></div>
    </>
  );
};

export default Slider;
