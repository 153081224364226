import Typography from "components/Typography";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";

import "./styles.scss";

type PaginationProps = {};

const Pagination: React.FC<PaginationProps> = ({}) => {
  return (
    <nav className="pagination">
      <button className="pagination__arrow pagination__arrow_left">
        <ArrowIcon height={16} />
      </button>
      <button className="pagination__arrow">
        <Typography variant="body1">1</Typography>
      </button>
      <button className="pagination__arrow">
        <Typography variant="body1">2</Typography>
      </button>
      <button className="pagination__arrow">
        <Typography variant="body1">3</Typography>
      </button>
      <button className="pagination__arrow">
        <Typography variant="body1">...</Typography>
      </button>
      <button className="pagination__arrow">
        <Typography variant="body1">18</Typography>
      </button>
      <button className="pagination__arrow pagination__arrow_right">
        <ArrowIcon height={16} />
      </button>
    </nav>
  );
};

export default Pagination;
