import sliderImage1 from "assets/images/main-slider1.webp";
import sliderImage2 from "assets/images/main-slider2.webp";
import sliderImage3 from "assets/images/main-slider3.webp";
import sliderImage4 from "assets/images/main-slider4.webp";
import sliderImage5 from "assets/images/main-slider5.webp";

export const sliderData = [
  {
    id: 1,
    image: sliderImage1,
    title: "Выбирайте нужный препарат через каталог",
  },
  {
    id: 2,
    image: sliderImage2,
    title: "Отслеживайте статус заказа в личном кабинете",
  },
  {
    id: 3,
    image: sliderImage3,
    title: "Партнер: Добавляйте свои товары и события",
  },
  {
    id: 4,
    image: sliderImage4,
    title: "Записывайтесь на уникальная события",
  },
  {
    id: 5,
    image: sliderImage5,
    title: "Онлайн конференции (в разработке)",
  },
];
