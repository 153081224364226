// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { UseQueryResult } from "@tanstack/react-query";
import { AccessLevelService, BrandService, CityService, EventCategoryService, EventService, ProductService, StatusService, UserClinicService, UsersPermissionsAuthService, UsersPermissionsUsersRolesService } from "../requests/services.gen";
export type AccessLevelServiceGetAccessLevelsDefaultResponse = Awaited<ReturnType<typeof AccessLevelService.getAccessLevels>>;
export type AccessLevelServiceGetAccessLevelsQueryResult<TData = AccessLevelServiceGetAccessLevelsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccessLevelServiceGetAccessLevelsKey = "AccessLevelServiceGetAccessLevels";
export const UseAccessLevelServiceGetAccessLevelsKeyFn = ({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: Array<unknown>) => [useAccessLevelServiceGetAccessLevelsKey, ...(queryKey ?? [{ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }])];
export type AccessLevelServiceGetAccessLevelsIdDefaultResponse = Awaited<ReturnType<typeof AccessLevelService.getAccessLevelsId>>;
export type AccessLevelServiceGetAccessLevelsIdQueryResult<TData = AccessLevelServiceGetAccessLevelsIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccessLevelServiceGetAccessLevelsIdKey = "AccessLevelServiceGetAccessLevelsId";
export const UseAccessLevelServiceGetAccessLevelsIdKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useAccessLevelServiceGetAccessLevelsIdKey, ...(queryKey ?? [{ id }])];
export type BrandServiceGetBrandsDefaultResponse = Awaited<ReturnType<typeof BrandService.getBrands>>;
export type BrandServiceGetBrandsQueryResult<TData = BrandServiceGetBrandsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBrandServiceGetBrandsKey = "BrandServiceGetBrands";
export const UseBrandServiceGetBrandsKeyFn = ({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: Array<unknown>) => [useBrandServiceGetBrandsKey, ...(queryKey ?? [{ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }])];
export type BrandServiceGetBrandsIdDefaultResponse = Awaited<ReturnType<typeof BrandService.getBrandsId>>;
export type BrandServiceGetBrandsIdQueryResult<TData = BrandServiceGetBrandsIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useBrandServiceGetBrandsIdKey = "BrandServiceGetBrandsId";
export const UseBrandServiceGetBrandsIdKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useBrandServiceGetBrandsIdKey, ...(queryKey ?? [{ id }])];
export type CityServiceGetCitiesDefaultResponse = Awaited<ReturnType<typeof CityService.getCities>>;
export type CityServiceGetCitiesQueryResult<TData = CityServiceGetCitiesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCityServiceGetCitiesKey = "CityServiceGetCities";
export const UseCityServiceGetCitiesKeyFn = ({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: Array<unknown>) => [useCityServiceGetCitiesKey, ...(queryKey ?? [{ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }])];
export type CityServiceGetCitiesIdDefaultResponse = Awaited<ReturnType<typeof CityService.getCitiesId>>;
export type CityServiceGetCitiesIdQueryResult<TData = CityServiceGetCitiesIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCityServiceGetCitiesIdKey = "CityServiceGetCitiesId";
export const UseCityServiceGetCitiesIdKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useCityServiceGetCitiesIdKey, ...(queryKey ?? [{ id }])];
export type EventServiceGetEventsDefaultResponse = Awaited<ReturnType<typeof EventService.getEvents>>;
export type EventServiceGetEventsQueryResult<TData = EventServiceGetEventsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEventServiceGetEventsKey = "EventServiceGetEvents";
export const UseEventServiceGetEventsKeyFn = ({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: Array<unknown>) => [useEventServiceGetEventsKey, ...(queryKey ?? [{ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }])];
export type EventServiceGetEventsIdDefaultResponse = Awaited<ReturnType<typeof EventService.getEventsId>>;
export type EventServiceGetEventsIdQueryResult<TData = EventServiceGetEventsIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEventServiceGetEventsIdKey = "EventServiceGetEventsId";
export const UseEventServiceGetEventsIdKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useEventServiceGetEventsIdKey, ...(queryKey ?? [{ id }])];
export type EventCategoryServiceGetEventCategoriesDefaultResponse = Awaited<ReturnType<typeof EventCategoryService.getEventCategories>>;
export type EventCategoryServiceGetEventCategoriesQueryResult<TData = EventCategoryServiceGetEventCategoriesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEventCategoryServiceGetEventCategoriesKey = "EventCategoryServiceGetEventCategories";
export const UseEventCategoryServiceGetEventCategoriesKeyFn = ({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: Array<unknown>) => [useEventCategoryServiceGetEventCategoriesKey, ...(queryKey ?? [{ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }])];
export type EventCategoryServiceGetEventCategoriesIdDefaultResponse = Awaited<ReturnType<typeof EventCategoryService.getEventCategoriesId>>;
export type EventCategoryServiceGetEventCategoriesIdQueryResult<TData = EventCategoryServiceGetEventCategoriesIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEventCategoryServiceGetEventCategoriesIdKey = "EventCategoryServiceGetEventCategoriesId";
export const UseEventCategoryServiceGetEventCategoriesIdKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useEventCategoryServiceGetEventCategoriesIdKey, ...(queryKey ?? [{ id }])];
export type ProductServiceGetProductsDefaultResponse = Awaited<ReturnType<typeof ProductService.getProducts>>;
export type ProductServiceGetProductsQueryResult<TData = ProductServiceGetProductsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useProductServiceGetProductsKey = "ProductServiceGetProducts";
export const UseProductServiceGetProductsKeyFn = ({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: Array<unknown>) => [useProductServiceGetProductsKey, ...(queryKey ?? [{ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }])];
export type ProductServiceGetProductsIdDefaultResponse = Awaited<ReturnType<typeof ProductService.getProductsId>>;
export type ProductServiceGetProductsIdQueryResult<TData = ProductServiceGetProductsIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useProductServiceGetProductsIdKey = "ProductServiceGetProductsId";
export const UseProductServiceGetProductsIdKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useProductServiceGetProductsIdKey, ...(queryKey ?? [{ id }])];
export type StatusServiceGetStatusesDefaultResponse = Awaited<ReturnType<typeof StatusService.getStatuses>>;
export type StatusServiceGetStatusesQueryResult<TData = StatusServiceGetStatusesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useStatusServiceGetStatusesKey = "StatusServiceGetStatuses";
export const UseStatusServiceGetStatusesKeyFn = ({ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }: {
  fields?: string;
  filters?: { [key: string]: unknown; };
  locale?: string;
  paginationLimit?: number;
  paginationPage?: number;
  paginationPageSize?: number;
  paginationStart?: number;
  paginationWithCount?: boolean;
  populate?: string;
  sort?: string;
} = {}, queryKey?: Array<unknown>) => [useStatusServiceGetStatusesKey, ...(queryKey ?? [{ fields, filters, locale, paginationLimit, paginationPage, paginationPageSize, paginationStart, paginationWithCount, populate, sort }])];
export type StatusServiceGetStatusesIdDefaultResponse = Awaited<ReturnType<typeof StatusService.getStatusesId>>;
export type StatusServiceGetStatusesIdQueryResult<TData = StatusServiceGetStatusesIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useStatusServiceGetStatusesIdKey = "StatusServiceGetStatusesId";
export const UseStatusServiceGetStatusesIdKeyFn = ({ id }: {
  id: number;
}, queryKey?: Array<unknown>) => [useStatusServiceGetStatusesIdKey, ...(queryKey ?? [{ id }])];
export type UsersPermissionsAuthServiceGetConnectByProviderDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsAuthService.getConnectByProvider>>;
export type UsersPermissionsAuthServiceGetConnectByProviderQueryResult<TData = UsersPermissionsAuthServiceGetConnectByProviderDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsAuthServiceGetConnectByProviderKey = "UsersPermissionsAuthServiceGetConnectByProvider";
export const UseUsersPermissionsAuthServiceGetConnectByProviderKeyFn = ({ provider }: {
  provider: string;
}, queryKey?: Array<unknown>) => [useUsersPermissionsAuthServiceGetConnectByProviderKey, ...(queryKey ?? [{ provider }])];
export type UsersPermissionsAuthServiceGetAuthByProviderCallbackDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsAuthService.getAuthByProviderCallback>>;
export type UsersPermissionsAuthServiceGetAuthByProviderCallbackQueryResult<TData = UsersPermissionsAuthServiceGetAuthByProviderCallbackDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsAuthServiceGetAuthByProviderCallbackKey = "UsersPermissionsAuthServiceGetAuthByProviderCallback";
export const UseUsersPermissionsAuthServiceGetAuthByProviderCallbackKeyFn = ({ provider }: {
  provider: string;
}, queryKey?: Array<unknown>) => [useUsersPermissionsAuthServiceGetAuthByProviderCallbackKey, ...(queryKey ?? [{ provider }])];
export type UsersPermissionsAuthServiceGetAuthEmailConfirmationDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsAuthService.getAuthEmailConfirmation>>;
export type UsersPermissionsAuthServiceGetAuthEmailConfirmationQueryResult<TData = UsersPermissionsAuthServiceGetAuthEmailConfirmationDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsAuthServiceGetAuthEmailConfirmationKey = "UsersPermissionsAuthServiceGetAuthEmailConfirmation";
export const UseUsersPermissionsAuthServiceGetAuthEmailConfirmationKeyFn = ({ confirmation }: {
  confirmation?: string;
} = {}, queryKey?: Array<unknown>) => [useUsersPermissionsAuthServiceGetAuthEmailConfirmationKey, ...(queryKey ?? [{ confirmation }])];
export type UsersPermissionsUsersRolesServiceGetUsersPermissionsPermissionsDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.getUsersPermissionsPermissions>>;
export type UsersPermissionsUsersRolesServiceGetUsersPermissionsPermissionsQueryResult<TData = UsersPermissionsUsersRolesServiceGetUsersPermissionsPermissionsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsUsersRolesServiceGetUsersPermissionsPermissionsKey = "UsersPermissionsUsersRolesServiceGetUsersPermissionsPermissions";
export const UseUsersPermissionsUsersRolesServiceGetUsersPermissionsPermissionsKeyFn = (queryKey?: Array<unknown>) => [useUsersPermissionsUsersRolesServiceGetUsersPermissionsPermissionsKey, ...(queryKey ?? [])];
export type UsersPermissionsUsersRolesServiceGetUsersPermissionsRolesDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.getUsersPermissionsRoles>>;
export type UsersPermissionsUsersRolesServiceGetUsersPermissionsRolesQueryResult<TData = UsersPermissionsUsersRolesServiceGetUsersPermissionsRolesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsUsersRolesServiceGetUsersPermissionsRolesKey = "UsersPermissionsUsersRolesServiceGetUsersPermissionsRoles";
export const UseUsersPermissionsUsersRolesServiceGetUsersPermissionsRolesKeyFn = (queryKey?: Array<unknown>) => [useUsersPermissionsUsersRolesServiceGetUsersPermissionsRolesKey, ...(queryKey ?? [])];
export type UsersPermissionsUsersRolesServiceGetUsersPermissionsRolesByIdDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.getUsersPermissionsRolesById>>;
export type UsersPermissionsUsersRolesServiceGetUsersPermissionsRolesByIdQueryResult<TData = UsersPermissionsUsersRolesServiceGetUsersPermissionsRolesByIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsUsersRolesServiceGetUsersPermissionsRolesByIdKey = "UsersPermissionsUsersRolesServiceGetUsersPermissionsRolesById";
export const UseUsersPermissionsUsersRolesServiceGetUsersPermissionsRolesByIdKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useUsersPermissionsUsersRolesServiceGetUsersPermissionsRolesByIdKey, ...(queryKey ?? [{ id }])];
export type UsersPermissionsUsersRolesServiceGetUsersDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.getUsers>>;
export type UsersPermissionsUsersRolesServiceGetUsersQueryResult<TData = UsersPermissionsUsersRolesServiceGetUsersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsUsersRolesServiceGetUsersKey = "UsersPermissionsUsersRolesServiceGetUsers";
export const UseUsersPermissionsUsersRolesServiceGetUsersKeyFn = (queryKey?: Array<unknown>) => [useUsersPermissionsUsersRolesServiceGetUsersKey, ...(queryKey ?? [])];
export type UsersPermissionsUsersRolesServiceGetUsersByIdDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.getUsersById>>;
export type UsersPermissionsUsersRolesServiceGetUsersByIdQueryResult<TData = UsersPermissionsUsersRolesServiceGetUsersByIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsUsersRolesServiceGetUsersByIdKey = "UsersPermissionsUsersRolesServiceGetUsersById";
export const UseUsersPermissionsUsersRolesServiceGetUsersByIdKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useUsersPermissionsUsersRolesServiceGetUsersByIdKey, ...(queryKey ?? [{ id }])];
export type UsersPermissionsUsersRolesServiceGetUsersMeDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.getUsersMe>>;
export type UsersPermissionsUsersRolesServiceGetUsersMeQueryResult<TData = UsersPermissionsUsersRolesServiceGetUsersMeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsUsersRolesServiceGetUsersMeKey = "UsersPermissionsUsersRolesServiceGetUsersMe";
export const UseUsersPermissionsUsersRolesServiceGetUsersMeKeyFn = (queryKey?: Array<unknown>) => [useUsersPermissionsUsersRolesServiceGetUsersMeKey, ...(queryKey ?? [])];
export type UsersPermissionsUsersRolesServiceGetUsersCountDefaultResponse = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.getUsersCount>>;
export type UsersPermissionsUsersRolesServiceGetUsersCountQueryResult<TData = UsersPermissionsUsersRolesServiceGetUsersCountDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersPermissionsUsersRolesServiceGetUsersCountKey = "UsersPermissionsUsersRolesServiceGetUsersCount";
export const UseUsersPermissionsUsersRolesServiceGetUsersCountKeyFn = (queryKey?: Array<unknown>) => [useUsersPermissionsUsersRolesServiceGetUsersCountKey, ...(queryKey ?? [])];
export type AccessLevelServicePostAccessLevelsMutationResult = Awaited<ReturnType<typeof AccessLevelService.postAccessLevels>>;
export type BrandServicePostBrandsMutationResult = Awaited<ReturnType<typeof BrandService.postBrands>>;
export type CityServicePostCitiesMutationResult = Awaited<ReturnType<typeof CityService.postCities>>;
export type EventServicePostEventsMutationResult = Awaited<ReturnType<typeof EventService.postEvents>>;
export type EventCategoryServicePostEventCategoriesMutationResult = Awaited<ReturnType<typeof EventCategoryService.postEventCategories>>;
export type ProductServicePostProductsMutationResult = Awaited<ReturnType<typeof ProductService.postProducts>>;
export type StatusServicePostStatusesMutationResult = Awaited<ReturnType<typeof StatusService.postStatuses>>;
export type UserClinicServicePostUsersClinicMutationResult = Awaited<ReturnType<typeof UserClinicService.postUsersClinic>>;
export type UsersPermissionsAuthServicePostAuthLocalMutationResult = Awaited<ReturnType<typeof UsersPermissionsAuthService.postAuthLocal>>;
export type UsersPermissionsAuthServicePostAuthLocalRegisterMutationResult = Awaited<ReturnType<typeof UsersPermissionsAuthService.postAuthLocalRegister>>;
export type UsersPermissionsAuthServicePostAuthForgotPasswordMutationResult = Awaited<ReturnType<typeof UsersPermissionsAuthService.postAuthForgotPassword>>;
export type UsersPermissionsAuthServicePostAuthResetPasswordMutationResult = Awaited<ReturnType<typeof UsersPermissionsAuthService.postAuthResetPassword>>;
export type UsersPermissionsAuthServicePostAuthChangePasswordMutationResult = Awaited<ReturnType<typeof UsersPermissionsAuthService.postAuthChangePassword>>;
export type UsersPermissionsAuthServicePostAuthSendEmailConfirmationMutationResult = Awaited<ReturnType<typeof UsersPermissionsAuthService.postAuthSendEmailConfirmation>>;
export type UsersPermissionsUsersRolesServicePostUsersPermissionsRolesMutationResult = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.postUsersPermissionsRoles>>;
export type UsersPermissionsUsersRolesServicePostUsersMutationResult = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.postUsers>>;
export type AccessLevelServicePutAccessLevelsIdMutationResult = Awaited<ReturnType<typeof AccessLevelService.putAccessLevelsId>>;
export type BrandServicePutBrandsIdMutationResult = Awaited<ReturnType<typeof BrandService.putBrandsId>>;
export type CityServicePutCitiesIdMutationResult = Awaited<ReturnType<typeof CityService.putCitiesId>>;
export type EventServicePutEventsIdMutationResult = Awaited<ReturnType<typeof EventService.putEventsId>>;
export type EventCategoryServicePutEventCategoriesIdMutationResult = Awaited<ReturnType<typeof EventCategoryService.putEventCategoriesId>>;
export type ProductServicePutProductsIdMutationResult = Awaited<ReturnType<typeof ProductService.putProductsId>>;
export type StatusServicePutStatusesIdMutationResult = Awaited<ReturnType<typeof StatusService.putStatusesId>>;
export type UsersPermissionsUsersRolesServicePutUsersPermissionsRolesByRoleMutationResult = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.putUsersPermissionsRolesByRole>>;
export type UsersPermissionsUsersRolesServicePutUsersByIdMutationResult = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.putUsersById>>;
export type AccessLevelServiceDeleteAccessLevelsIdMutationResult = Awaited<ReturnType<typeof AccessLevelService.deleteAccessLevelsId>>;
export type BrandServiceDeleteBrandsIdMutationResult = Awaited<ReturnType<typeof BrandService.deleteBrandsId>>;
export type CityServiceDeleteCitiesIdMutationResult = Awaited<ReturnType<typeof CityService.deleteCitiesId>>;
export type EventServiceDeleteEventsIdMutationResult = Awaited<ReturnType<typeof EventService.deleteEventsId>>;
export type EventCategoryServiceDeleteEventCategoriesIdMutationResult = Awaited<ReturnType<typeof EventCategoryService.deleteEventCategoriesId>>;
export type ProductServiceDeleteProductsIdMutationResult = Awaited<ReturnType<typeof ProductService.deleteProductsId>>;
export type StatusServiceDeleteStatusesIdMutationResult = Awaited<ReturnType<typeof StatusService.deleteStatusesId>>;
export type UsersPermissionsUsersRolesServiceDeleteUsersPermissionsRolesByRoleMutationResult = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.deleteUsersPermissionsRolesByRole>>;
export type UsersPermissionsUsersRolesServiceDeleteUsersByIdMutationResult = Awaited<ReturnType<typeof UsersPermissionsUsersRolesService.deleteUsersById>>;
