import Typography from "components/Typography";
import "./styles.scss";

type TabsProps = {
  brands?: boolean;
  current?: "catalog" | "events";
  onChange?: (tab: "catalog" | "events") => void;
  full?: boolean;
};

const Tabs: React.FC<TabsProps> = ({ brands, onChange, current, full }) => {
  if (brands) {
    return (
      <div className="tabs">
        <div
          className={`tabs__item ${
            current === "catalog" ? "tabs__item_active" : ""
          } ${full ? "tabs__item_full" : ""}`}
          onClick={() => {
            onChange?.("catalog");
          }}
        >
          <Typography>Товары компании</Typography>
        </div>
        <div
          className={`tabs__item ${
            current === "events" ? "tabs__item_active" : ""
          } ${full ? "tabs__item_full" : ""}`}
          onClick={() => {
            onChange?.("events");
          }}
        >
          <Typography>События</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="tabs">
      <div className="tabs__item tabs__item_active">
        <Typography>Описание</Typography>
      </div>
      <div className="tabs__item">
        <Typography>Инструкция</Typography>
      </div>
      <div className="tabs__item">
        <Typography>Протоколы</Typography>
      </div>
      <div className="tabs__item">
        <Typography>Сертификаты</Typography>
      </div>
    </div>
  );
};

export default Tabs;
