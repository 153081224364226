import { useState } from "react";

import Title from "components/Title";
import Header from "components/Header";
import Typography from "components/Typography";
import List from "components/List";

import { paths } from "constants/paths";
import { chapterData } from "./data/chapter";

import "./styles.scss";

const CatalogPage = () => {
  const title = "Каталог";
  const [search, setSearch] = useState("");
  const [list, setList] = useState(chapterData);

  const onChangeSearch = (value: string) => {
    setSearch(value);

    if (value?.trim()) {
      setList(
        list.filter((el) =>
          el.text
            .toLocaleLowerCase()
            .trim()
            .includes(value.toLocaleLowerCase().trim())
        )
      );
    } else {
      setList(chapterData);
    }
  };

  return (
    <div className="wrapper">
      <Title>{title}</Title>

      <Header
        goBack={paths.home}
        title={"Каталог"}
        search={search}
        onChangeSearch={onChangeSearch}
      />

      <div className="catalog">
        <Typography variant="h3" style={{ marginBottom: 8 }}>
          {title}
        </Typography>

        <List data={list} />
      </div>
    </div>
  );
};

export default CatalogPage;
