import { HelmetProvider } from "react-helmet-async";

import Router from "./Routes";

import "./colors.scss";
import "./styles.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div id="app">
        <HelmetProvider>
          <Router />
        </HelmetProvider>
      </div>
    </QueryClientProvider>
  );
}

export default App;
