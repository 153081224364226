import { CSSProperties } from "react";

import "./styles.scss";

type SearchType = {
  value: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  className?: string;
  placeholder?: string;
};

const Search: React.FC<SearchType> = ({
  value,
  onChange,
  style,
  className,
  placeholder,
}) => {
  return (
    <div className={`search ${className}`} style={style}>
      <input
        type="text"
        className="search__input"
        placeholder={placeholder || "Поиск в COSMETICZONE"}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </div>
  );
};

export default Search;
