import { useState } from "react";

const useAccountProductsPage = () => {
  const title = "Товары";

  const [sortingIsOpen, setSortingIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const onChangeSearch = (value: string) => {
    setSearch(value);
  };

  return { title, sortingIsOpen, setSortingIsOpen, search, onChangeSearch };
};

export default useAccountProductsPage;
