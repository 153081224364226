import { AccordionItem } from "@szhsin/react-accordion";

import File from "components/File";

import { ReactComponent as ArrowIcon } from "assets/icons/faq-arrow.svg";

const ProductCertificates = () => {
  return (
    <AccordionItem
      header={
        <>
          Сертификаты
          <ArrowIcon className="chevron-down" />
        </>
      }
    >
      <div className="product-accordion">
        {/* <File
          style={{ marginBottom: 12 }}
          fileName="ТР ТС 009/2011 О безопасности парфюме... .pdf"
        /> */}
        {/* <File fileName="EAЭС N RU Д-PL.PA03.B/40319/22 от 02.05... .pdf" /> */}
      </div>
    </AccordionItem>
  );
};

export default ProductCertificates;
