import Title from "components/Title";
import Header from "components/Header";
import Input from "components/Input";
import Button from "components/Button";
import TextArea from "components/TextArea";

import { paths } from "constants/paths";

import "./styles.scss";

const AccountEventsAddPage = () => {
  const title = "Добавление события";

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header goBack title={title} />

        <div className="events-add">
          <div className="events-add__image">
            <div className="events-add__image__icon" />
            <div className="events-add__image__content">
              <div className="events-add__image__title">Обложка события</div>
              <div className="events-add__image__text">
                в формате PNG, JPEG до 10 Мб
              </div>
            </div>
          </div>
          <Input required label="Заголовок" style={{ marginBottom: 16 }} />
          <Input
            required
            label="Категория"
            placeholder="Выберите категорию"
            style={{ marginBottom: 16 }}
          />
          <Input
            required
            label="Место проведения"
            style={{ marginBottom: 16 }}
          />
          <Input
            required
            label="Количество мест"
            placeholder="Неограниченно"
            style={{ marginBottom: 16 }}
          />
          <Input required label="Дата и время" style={{ marginBottom: 16 }} />
          <div className="events-add__inputs">
            <Input label="Продолжительность" placeholder="мин" />
            <Input
              required
              label="Стоимость"
              placeholder="Бесплатно"
              style={{ marginBottom: 16 }}
            />
          </div>

          <div className="events-add__divider" />

          <div className="events-add__section">
            <TextArea label="О событии" style={{ marginBottom: 16 }} />
            <TextArea label="Спикеры" />
          </div>
          <div className="events-add__button">
            <Button full size="large" to={`${paths.events}/1`}>
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountEventsAddPage;
