import { AccordionItem } from "@szhsin/react-accordion";

import Typography from "components/Typography";

import { ReactComponent as ArrowIcon } from "assets/icons/faq-arrow.svg";

const ProductInstructions = () => {
  return (
    <AccordionItem
      header={
        <>
          Инструкция
          <ArrowIcon className="chevron-down" />
        </>
      }
    >
      <div className="product-accordion">
        <Typography>
          Мезотерапия должна проводится опытным косметологом, средняя
          длительность процедуры – 30-35 минут. Подробное описание процедуры
          указано в инструкции по применению, в комплекте препарата. Перед
          терапией необходимо очистить и продезинфицировать кожу в местах
          введения препарата, для максимального комфорта пациента – нанести
          местную анестезию. Мезопрепарат Нития Стимулейт вводится инъекционным
          методом, иглами – 30-32G, в поверхностные и глубокие слои дермы.
          Необходимо развести 70 мг порошка из флакона с физраствором в объеме 5
          мл. По завершению инъекции вновь обработать кожу антисептиком и
          провести легких массаж при необходимости. Рекомендованный курс состоит
          из 3 терапий с промежутком – 15 дней. Повторная терапия назначается
          косметологом, в зависимости от дефектов кожи, средний интервал 3-6
          месяцев.
        </Typography>
      </div>
    </AccordionItem>
  );
};

export default ProductInstructions;
