import { Link } from "react-router-dom";

import Typography from "components/Typography";
import Button from "components/Button";
import Title from "components/Title";
import Header from "components/Header";

import useLoginPage from "./useLoginPage";
import { paths } from "constants/paths";

import "./styles.scss";

const SignUpPage = () => {
  const title = "Регистрация";

  const {} = useLoginPage();

  return (
    <div className="wrapper">
      <Title>{title}</Title>

      <Header />

      <div className="login">
        <Typography variant="h3" style={{ marginBottom: 24 }}>
          Регистрация на платформе
        </Typography>

        <Typography className={"subhead textGray"} style={{ marginBottom: 12 }}>
          Хочу покупать
        </Typography>

        <div className="login-cards">
          <Link
            to={paths.signUpCosmetologist}
            state={{ signUp: { title: "Косметолог" } }}
            className="login-cards__item login-cards__item_user"
          >
            <Typography className={"subheadM"}>Косметолог</Typography>
          </Link>
          <Link
            to={paths.signUpClinic}
            state={{ signUp: { title: "Клиника" } }}
            className="login-cards__item login-cards__item_hospital"
          >
            <Typography className={"subheadM"}>Клиника</Typography>
          </Link>
        </div>

        <Typography className={"subhead textGray"} style={{ marginBottom: 12 }}>
          Хочу продавать
        </Typography>

        <div className="login-cards" style={{ marginBottom: 48 }}>
          <Link
            to={paths.signUp}
            state={{ signUp: { title: "Партнер" } }}
            className="login-cards__item login-cards__item_shop"
          >
            <Typography className={"subheadM"}>Партнер</Typography>
          </Link>
        </div>

        <Typography className={"subhead textGray"} style={{ marginBottom: 12 }}>
          Уже есть аккаунт?
        </Typography>

        <Button to={paths.signIn} full size="large">
          Войти
        </Button>
      </div>
    </div>
  );
};

export default SignUpPage;
