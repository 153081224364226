import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Title from "components/Title";
import Header from "components/Header";
import Typography from "components/Typography";
import Input from "components/Input";
import Button from "components/Button";
import AddOptions from "./components/AddOptions";
import TextArea from "components/TextArea";

import { paths } from "constants/paths";

import { ReactComponent as AttachIcon } from "assets/icons/attach.svg";

import "./styles.scss";

const AccountProductsAddPage = () => {
  const location = useLocation();
  const [title, setTitle] = useState("Добавление товара");

  useEffect(() => {
    if (location?.state?.edit) {
      setTitle("Редактирование товара");
    }
  }, [location?.state?.edit]);

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header goBack title={title} />

        <div className="account-product-add">
          <div className="account-product-add__section">
            <div className="account-product-add__section__inner">
              <Typography style={{ marginBottom: 5 }}>
                <strong>Фото товара</strong>
              </Typography>
              <Typography style={{ color: "var(--gray)" }}>
                До 10 фото в формате PNG, JPEG до 10 Мб
              </Typography>
              <div className="account-product-add__photo" />

              <Input
                label="Название товара"
                required
                placeholder="Cosmetic name"
                style={{ marginBottom: 16 }}
              />

              <Input
                label="Категория"
                required
                placeholder="Выберите категорию товара"
                style={{ marginBottom: 16 }}
              />

              <div className="account-product-add__inputs">
                <Input
                  label="Цена за штуку"
                  required
                  placeholder="Cosmetic name"
                />

                <Input label="Категория" required placeholder="Наличие" />
              </div>

              <Input
                label="Страна производства"
                required
                placeholder="Выберите страну"
              />
            </div>
          </div>

          <div className="account-product-add__section">
            <div className="account-product-add__section__inner">
              <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
                Характеристики
              </Typography>

              <Input
                label="Форма выпуска"
                required
                placeholder="Выберите форму выпуска"
                style={{ marginBottom: 16 }}
              />

              <Input
                label="Упаковка"
                required
                placeholder="Выберите тип упаковки"
                style={{ marginBottom: 16 }}
              />

              <AddOptions />
            </div>
          </div>

          <div className="account-product-add__section">
            <div className="account-product-add__section__inner">
              <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
                Описание товара, инструкция
              </Typography>

              <TextArea
                label="Описание"
                placeholder="Описание товара, маркетинговый текст"
                style={{ marginBottom: 16 }}
              />

              <TextArea
                label="Инструкцию по применению"
                style={{ marginBottom: 16 }}
              />

              <div className="account-product-add__accordion">
                <Typography variant="subtitle1" style={{ marginBottom: 6 }}>
                  Другая информация
                </Typography>
                <Typography style={{ color: "var(--gray)" }}>
                  Состав, подготовка, меры предосторожности, курс,
                  противопоказания, показания...
                </Typography>
              </div>

              <Input required label="Номер регистрац. удостоверения" />
            </div>
          </div>

          <div className="account-product-add__section">
            <div className="account-product-add__section__inner">
              <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
                Протоколы
              </Typography>
              <Button variant="second" startIcon={<AttachIcon />}>
                Добавить
              </Button>
              <Typography
                style={{ fontSize: 12, lineHeight: "16px", marginTop: 16 }}
              >
                Пошаговая инструкция по применению продукта, которая включает в
                себя рекомендации по использованию, дозировке, частоте
                применения и ожидаемым результатам.
              </Typography>
            </div>
          </div>

          <div className="account-product-add__section">
            <div className="account-product-add__section__inner">
              <Typography variant="subtitle1" style={{ marginBottom: 16 }}>
                Сертификаты
              </Typography>
              <Button variant="second" startIcon={<AttachIcon />}>
                Добавить
              </Button>
              <Typography
                style={{ fontSize: 12, lineHeight: "16px", marginTop: 16 }}
              >
                Документы, подтверждающие качество и безопасность продукции. Они
                могут быть национальными или международными и выдаются после
                проведения необходимых исследований и экспертиз.
              </Typography>
            </div>
          </div>

          <Button full size="large" to={paths.accountProducts}>
            Сохранить
          </Button>
        </div>
      </div>
    </>
  );
};

export default AccountProductsAddPage;
