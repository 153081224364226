import Crumbs from "components/Crumbs";
import Footer from "components/Footer";
import Header from "components/Header";
import Title from "components/Title";
import Typography from "components/Typography";
import Button from "components/Button";

import { ReactComponent as OptionsIcon } from "assets/icons/options.svg";
import { ReactComponent as CosmetologIcon } from "assets/icons/cosmetolog.svg";
import { ReactComponent as DeliveryIcon } from "assets/icons/delivery.svg";
import { data1, data2 } from "pages/CartPage";

import { paths } from "constants/paths";

import "./styles.scss";

const AccountOrdersPage = () => {
  const title = "Мои заказы";

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header />

        <div className="account-order-id">
          <Crumbs path={title || ""} />

          <div className="account-order-id__content">
            <div className="account-order-id__item">
              <Typography className="account-order-id__date">
                Заказ от 24 августа
              </Typography>
              <Typography className="account-order-id__status">
                Ожидает сборки
              </Typography>
            </div>
            <Typography className="account-order-id__number">
              №1177738-1
            </Typography>

            <div className="order__item__header">
              <DeliveryIcon className="order__item__header__icon" />
              <div className="order__item__header__title">
                Доставка по адресу
              </div>
            </div>

            <Typography className="account-order-id__address-title">
              Адрес
            </Typography>
            <Typography className="account-order-id__address">
              Будапештская улица, 46, Санкт-Петербург
            </Typography>
            <Typography className="account-order-id__track">
              Транспортная компания
            </Typography>
            <Typography className="account-order-id__track-number">
              СДЭК
            </Typography>
          </div>

          <div className="account-order-id__content">
            <div className="order__item__header">
              <CosmetologIcon className="order__item__header__icon" />
              <div className="order__item__header__title">Получатель</div>
            </div>

            <div className="account-order-id__recipient">
              Быкова Анастасия Михайловна <br />
              +7 952 325 96 70 <br />
              userexample@mail.ru
            </div>
          </div>

          <div className="account-order-id__list">
            <div className="account-order-id__list__inner">
              {[...data1, ...data2].map((el) => (
                <div className="cart__list__item" key={el.id}>
                  <img
                    src={el.image}
                    className="cart__list__item__image"
                    alt="product"
                  />
                  <div className="cart__list__item__content">
                    <div className="cart__list__item__content__item">
                      <div className="cart__list__item__title">{el.title}</div>
                    </div>
                    <div className="cart__list__item__content__item">
                      <div className="cart__list__item__label">{el.label}</div>
                    </div>
                    <div className="cart__list__item__content__item">
                      <div className="cart__list__item__price">{el.price}</div>
                      <Button style={{ marginTop: -20 }} variant="second">
                        Опции
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="account-order-id__buttons">
            <Button
              size="large"
              variant="second"
              startIcon={<OptionsIcon />}
              style={{ width: 48, paddingLeft: 24, marginRight: 8 }}
            >
              {""}
            </Button>
            <Button full size="large" to={paths.account}>
              Собрать заказ
            </Button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AccountOrdersPage;
