import { useState } from "react";

import Title from "components/Title";
import Header from "components/Header";
import Typography from "components/Typography";
import Button from "components/Button";
import Input from "components/Input";

import "./styles.scss";
import { paths } from "constants/paths";

const ForgotPasswordPage = () => {
  const title = "Восстановление пароля";
  const [step, setStep] = useState<1 | 2>(1);

  const [email, setEmail] = useState("");

  if (step === 1) {
    return (
      <div className="wrapper">
        <Title>{title}</Title>

        <Header goBack title="Восстановление пароля" />

        <div className="forgot-password">
          <Typography
            className="forgot-password__description"
            variant="subtitle2"
          >
            Введите адрес электронной почты, на который был зарегистрирован
            аккаунт
          </Typography>
          <Input
            required
            label="Ваш e-mail"
            placeholder="userexample@mail.ru"
            className="forgot-password__input"
            type={"email"}
            value={email}
            // onChange={(value) => {
            //   setEmail(value);
            // }}
          />
          <Button
            full
            size="large"
            onClick={() => {
              setStep(2);
            }}
          >
            Продолжить
          </Button>
        </div>
      </div>
    );
  }

  if (step === 2) {
    return (
      <div className="wrapper">
        <Title>{title}</Title>

        <Header goBack title="Восстановление пароля" />

        <div className="forgot-password">
          <Typography
            className="forgot-password__description"
            variant="subtitle2"
          >
            Введите код для сброса пароля, отправленный на адрес{" "}
            <span>{email}</span>
          </Typography>
          <Input
            required
            label="Код для сброса пароля"
            className="forgot-password__input"
            type={"number"}
          />
          <Button full size="large" to={paths.signIn}>
            Продолжить
          </Button>
        </div>
      </div>
    );
  }
  return <></>;
};

export default ForgotPasswordPage;
