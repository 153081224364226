import Title from "components/Title";
import Header from "components/Header";
import Crumbs from "components/Crumbs";
import Footer from "components/Footer";
import Typography from "components/Typography";

const PolicyPage = () => {
  const title = "Согласие на обработку персональных данных";

  return (
    <>
      <Title>{title}</Title>

      <Header />

      <section className="section-wrapper">
        <div className="wrapper">
          <div className="pageHead pageHead_smMg">
            <Crumbs path={title} />

            <Typography variant={"h3"}>{title}</Typography>
          </div>

          <div className="footnote textBlock textBlock_footnote">
            <p>
              Настоящее Согласие на обработку персональных данных (далее —
              Согласие) является официальным документом ИП Никитиной К. В.
              (далее — COSMETICZONE) и определяет порядок обработки и защиты
              информации о физических лицах (далее — Субъект), пользующихся
              сервисами, информацией, услугами, приложениями и продуктами
              интернет-магазина, расположенного на доменном имени
              www.cosmeticzone.ru.
            </p>
            <p>
              Обработка персональных данных COSMETICZONE, его уполномоченным
              представителем, организацией партнёром осуществляется с согласия
              субъекта персональных данных.
            </p>
            <p>
              Под персональными данными понимается любая информация, относящаяся
              прямо или косвенно к определённому или определяемому физическому
              лицу (субъекту персональных данных) и которая может быть
              использована для идентификации определённого лица при регистрации
              на сайте www.cosmeticzone.ru либо в иных действиях (совокупностях)
              действий (операций) совершаемых для участия в мероприятиях,
              размещаемых на сайте www.cosmeticzone.ru.
            </p>
            <p>
              Я согласен(на) на обработку и использование своих персональных
              данных, указываемых мной при регистрации и совершении записей на
              мероприятия на сайте www.cosmeticzone.ru, согласно Федеральному
              закону «О персональных данных» от 27.07.2006 № 152-ФЗ, и на любое
              действие (операцию) или совокупность действий (операций),
              совершаемых с моими персональными данными, включая сбор, запись,
              систематизацию, накопление, хранение, уточнение (обновление,
              изменение), извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных с использованием средств
              автоматизации, в том числе в информационно-телекоммуникационных
              сетях, или без использования таких средств, если обработка
              персональных данных без использования таких средств соответствует
              характеру действий (операций), совершаемых с персональными данными
              с использованием средств автоматизации, то есть позволяет
              осуществлять в соответствии с заданным алгоритмом поиск
              персональных данных, зафиксированных на материальном носителе и
              содержащихся в картотеках или иных систематизированных собраниях
              персональных данных, и/или доступ к таким персональным данным, а
              также на передачу (в том числе трансграничную) этих персональных
              данных уполномоченным представителям, организациям партнёрам
              COSMETICZONE в целях реализации проекта COSMETICZONE и (или)
              совместных программ.
            </p>
            <p>
              Настоящим я подтверждаю, что переданные мной в COSMETICZONE
              персональные данные: Фамилия, Имя, Отчество, адрес проживания и
              (или) регистрации, адрес(а) электронной почты, телефонные номера,
              дата и место рождения, данные об образовании, фотографии, ссылки
              на персональный сайт или соцсети являются достоверными и могут
              обрабатываться COSMETICZONE и его уполномоченными представителями,
              организациями партнёрами с заявленной целью.
            </p>
            <p>
              Я даю разрешение COSMETICZONE направлять мне информационную
              корреспонденцию на указанный мной электронный адрес, включающую в
              себя: приветственные письма и уведомления с указанием персональных
              данных, а также информацию о проводимых COSMETICZONE и
              организациями партнёрами проекта COSMETICZONE рекламных акциях, а
              также сообщения иного информационного характера, связанные с
              функционированием сервиса на сайте www.cosmeticzone.ru.
            </p>
            <p>
              Я согласен(на) с тем, что текст данного мной согласия хранится в
              электронном виде в специальном разделе Личного Кабинета по адресу
              www.cosmeticzone.ru и/или на бумажном носителе и подтверждает факт
              дачи www.cosmeticzone.ru согласия на обработку и передачу
              персональных данных в соответствии с вышеизложенными положениями.
              Согласие даётся на срок моей регистрации на сайте
              www.cosmeticzone.ru и/или участия в мероприятиях, размещаемых в
              COSMETICZONE (и до истечения 5 (пяти) лет после окончания года, в
              котором удалена моя регистрация на сайте www.cosmeticzone.ru и/или
              прекращено участие в проекте COSMETICZONE) и может быть в любой
              момент мной отозвано путём направления письменного уведомления
              (скан-копия оригинала) в адрес COSMETICZONE по адресу:
              info@cosmeticzone.ru.
            </p>
            <p>
              Я предупреждён(а), что направление мной указанного письменного
              уведомления об отзыве согласия на обработку персональных данных
              влечёт за собой прекращение регистрации на сайте
              www.cosmeticzone.ru, а также удаление информации о ранее
              совершённых мною регистрациях на мероприятия и ранее совершённых
              покупках на сайте www.cosmeticzone.ru.
            </p>
            <p>
              COSMETICZONE, его уполномоченные представители, организации
              партнёры обязуется не разглашать полученные от субъекта
              персональные данные. При этом не считается нарушением обязательств
              разглашение персональных данных в случае, когда обязанность такого
              раскрытия установлена требованиями действующего законодательства
              РФ.
            </p>
            <p>
              Все вопросы, касающиеся доступа пользователей к сайту
              www.cosmeticzone.ru и использования размещённой на нем информации,
              услуг, приложения и продуктов интернет-магазина регулируются
              действующим законодательством Российской Федерации. Любые спорные
              вопросы, касающиеся использования сайта, должны рассматриваться в
              судебной инстанции соответствующей юрисдикции, а именно, в
              Арбитражном суде г. Краснодар. COSMETICZONE оставляет за собой право
              вносить изменения в одностороннем порядке в настоящее Согласие.
              Изменения условий настоящего Согласия вступают в силу после их
              публикации на сайте www.cosmeticzone.ru.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default PolicyPage;
