import { useState } from "react";

import Title from "components/Title";
import Header from "components/Header";
import Typography from "components/Typography";

import { paths } from "constants/paths";
import { brandsData } from "./data/brands";

import "./styles.scss";
import { Link } from "react-router-dom";

const CatalogBrandsPage = () => {
  const title = "Бренды";
  const [search, setSearch] = useState("");
  const [brands, setBrands] = useState(brandsData);

  const onChangeSearch = (value: string) => {
    setSearch(value);

    if (value?.trim()) {
      setBrands(
        brands.filter((el) =>
          el.title
            .toLocaleLowerCase()
            .trim()
            .includes(value.toLocaleLowerCase().trim())
        )
      );
    } else {
      setBrands(brandsData);
    }
  };

  return (
    <div className="wrapper">
      <Title>{title}</Title>

      <Header
        goBack={paths.home}
        title={"Каталог"}
        search={search}
        onChangeSearch={onChangeSearch}
      />

      <div className="catalog-brands">
        <Typography variant="h3" style={{ marginBottom: 8 }}>
          {title}
        </Typography>
        <div className="catalog-brands__cards">
          {brands.map((el) => (
            <Link
              className="catalog-brands__card"
              key={el.id}
              to={`${paths.catalogBrands}/${el.id}`}
            >
              <img
                src={el.image}
                alt={el.title}
                className="catalog-brands__card__image"
              />
              <Typography className="catalog-brands__card__title">
                {el.title}
              </Typography>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CatalogBrandsPage;
