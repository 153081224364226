import Title from "components/Title";
import Header from "components/Header";
import Footer from "components/Footer";
import Sorting from "components/Sorting";

import useCatalogIdPage from "./useCatalogIdPage";

import { Catalog } from "components/Catalog";

const CatalogIdPage = () => {
  const { title, sortingIsOpen, setSortingIsOpen } = useCatalogIdPage();

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header />
        <Catalog
          title={title}
          sortingIsOpen={sortingIsOpen}
          setSortingIsOpen={setSortingIsOpen}
        />
      </div>

      <Sorting isOpen={sortingIsOpen} setOpen={setSortingIsOpen} />

      <Footer />
    </>
  );
};

export default CatalogIdPage;
