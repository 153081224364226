import Typography from "components/Typography";

import "./styles.scss";

const ProductCharacteristics = () => {
  return (
    <div className="product-characteristics">
      <div className="product-characteristics__item">
        <Typography>Артикул</Typography>
        <Typography>74593</Typography>
      </div>
      <div className="product-characteristics__item">
        <Typography>Категория</Typography>
        <Typography>Биореперанты</Typography>
      </div>
      <div className="product-characteristics__item">
        <Typography>Тип товара</Typography>
        <Typography>Сыворотка</Typography>
      </div>
      <div className="product-characteristics__item">
        <Typography>Объем</Typography>
        <Typography>5 мл.</Typography>
      </div>
      <div className="product-characteristics__item">
        <Typography>Количество в наборе</Typography>
        <Typography>1 шт.</Typography>
      </div>
      <div className="product-characteristics__item">
        <Typography>Страна производства</Typography>
        <Typography>Италия</Typography>
      </div>
    </div>
  );
};

export default ProductCharacteristics;
