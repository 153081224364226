import Title from "components/Title";
import Crumbs from "components/Crumbs";
import Header from "components/Header";
import Typography from "components/Typography";
import Footer from "components/Footer";
import Button from "components/Button";

import { paths } from "constants/paths";

import Image from "assets/images/event-image.png";
import Brand from "assets/images/brands/13.png";

import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import { ReactComponent as TimerIcon } from "assets/icons/timer.svg";

import "./styles.scss";

const EventsIdPage = () => {
  const title = "Событие";

  return (
    <div className="wrapper">
      <Title>{title}</Title>

      <Title>
        "Блиц-Вебинар. 90 минут! История одного пациента. Протокол №6 Нитевые
        технологии от простого к сложному"
      </Title>

      <Crumbs path="Блиц-Вебинар. 90 минут! История одного пациента. Протокол №6 Нитевые технологии от простого к сложному" />

      <Header />

      <div className="events-id">
        <div className="events-id__header">
          <img src={Brand} alt="brand" className="events-id__header__brand" />
          <Typography className="events-id__header__title">
            Академия Эстетики
          </Typography>
          <Button
            style={{ width: 34 }}
            variant="second"
            size="small"
            startIcon={<EditIcon style={{ marginLeft: 7 }} />}
            children={undefined}
            to={paths.accountEventsAdd}
          />
        </div>
        <img className="events-id__image" src={Image} alt="photo1" />
        <Typography className="events-id__title">
          Блиц-Вебинар. 90 минут! История одного пациента. Протокол №6 Нитевые
          технологии от простого к сложному
        </Typography>

        <div className="events-id__info">
          <div className="events-id__info__item">
            <CalendarIcon className="events-id__info__item__icon" />
            <Typography
              variant="subtitle2"
              className="events-id__info__item__text"
            >
              20 сентября, пятница, 19:00
            </Typography>
          </div>
          <div className="events-id__info__item">
            <LocationIcon className="events-id__info__item__icon" />
            <Typography
              variant="subtitle2"
              className="events-id__info__item__text"
            >
              Online, Zoom
            </Typography>
          </div>
          <div className="events-id__info__item">
            <TimerIcon className="events-id__info__item__icon" />
            <Typography
              variant="subtitle2"
              className="events-id__info__item__text"
            >
              2 часа
            </Typography>
          </div>
        </div>

        <div className="events-id__result">
          <Typography variant="subtitle2">Занято мест: 21 из 50</Typography>
        </div>
      </div>

      <div className="events-id__content">
        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
          О событии
        </Typography>
        <Typography style={{ marginBottom: 8 }}>
          Задачи и возможности тредлифтинга. Эволюция мезонитей. VITAMOL простых
          конфигураций ( Mono, Twin, Screw, TwinScrew), технические
          характеристики, преимущества, удобство выбора. Эффекты имплантации в
          зона введения.
          <br />
          <br />
          VITAMOL для лифтинга и перемещения мягких тканей (Cog, Molding Cog).
          Возможности использования.
          <br />
          <br />
          Комплексное использование простых и сложных нитей для достижения
          максимального эффекта. Сочетанные протоколы применения мезонитей с
          препаратами ГК . Авторские методики Моисеевой Н.Б.
          <br />
          <br />
          Мастер-класс на модели
        </Typography>

        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
          Спикеры
        </Typography>
        <Typography style={{ marginBottom: 16 }}>
          <ul>
            <li>Моисеева Наталья Борисовна</li>
            <li>Врач-дерматовенеролог высшей категории, дерматокосметолог.</li>
            <li>
              Международный сертифицированный тренер по мезотерапии, контурной
              пластике и тредлифтингу компаний Dr. Korman Laboratories Ltd,
              Израиль, RUMEDIX Inc, Корея.
            </li>
            <li>
              Генеральный директор учебно-медицинского центра дерматологии и
              косметологии «МедУспех»
            </li>
          </ul>
        </Typography>

        <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
          ОДоступ к событию
        </Typography>
        <Typography style={{ marginBottom: 8 }}>
          Средний медицинский персонал
        </Typography>
      </div>

      <div style={{ margin: "0 -16px" }}>
        <Footer />
      </div>
    </div>
  );
};

export default EventsIdPage;
