import { Link } from "react-router-dom";
import Crumbs from "components/Crumbs";
import Header from "components/Header";
import Title from "components/Title";
import Typography from "components/Typography";
import Button from "components/Button";
import { paths } from "constants/paths";
import Footer from "components/Footer";
import { brandsData } from "pages/CatalogBrandsPage/data/brands";

import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

import "./styles.scss";

const AccountBrandsPage = () => {
  const title = "Мои бренды";
  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header />

        <Crumbs path={title || ""} />

        <Typography variant="h3">{title}</Typography>
        <div className="account-brands">
          {brandsData.map((el) => (
            <Link
              className="account-brands__card"
              key={el.id}
              to={`${paths.catalogBrands}/${el.id}`}
              state={{ edit: true }}
            >
              <img
                src={el.image}
                alt={el.title}
                className="account-brands__card__image"
              />
              <div>
                <Typography className="account-brands__card__title">
                  {el.title}
                </Typography>

                <Typography className="account-brands__card__text">
                  48 товаров
                </Typography>
              </div>
            </Link>
          ))}
          <Button
            to={paths.accountBrandsCreate}
            full
            size="large"
            style={{ margin: "16px 0" }}
            variant="second"
            startIcon={<PlusIcon />}
          >
            Создать бренд
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccountBrandsPage;
