import Title from "components/Title";
import Header from "components/Header";
import Crumbs from "components/Crumbs";
import Typography from "components/Typography";
import Footer from "components/Footer";
import Button from "components/Button";
import Pagination from "components/Pagination";
import Sorting from "components/Sorting";
import Search from "components/Search";

import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as FilterIcon } from "assets/icons/filter-icon.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

import useAccountProductsPage from "./useAccountProductsPage";
import { data1, data2 } from "pages/CartPage";
import { paths } from "constants/paths";

import "./styles.scss";

const AccountProductsPage = () => {
  const { title, sortingIsOpen, setSortingIsOpen, search, onChangeSearch } =
    useAccountProductsPage();

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header />

        <div className="account-product">
          <Crumbs path={title || ""} />
          <div className="account-product__header">
            <Typography className="product__header__title" variant="h3">
              {title}
            </Typography>

            <Button
              to={paths.accountProductsAdd}
              style={{ marginBottom: 16 }}
              startIcon={<PlusIcon />}
            >
              Добавить товар
            </Button>
          </div>

          <Search
            value={search}
            onChange={onChangeSearch}
            placeholder="Поиск товара"
          />

          <div className="catalog-id__filter">
            <button
              className="catalog-id__filter__button"
              onClick={() => {
                setSortingIsOpen(true);
              }}
            >
              <Typography
                variant="subtitle2"
                className="catalog-id__filter__button__text"
              >
                Сначала новые
              </Typography>
              <ArrowIcon
                className={
                  "catalog-id__filter__button__icon catalog-id__filter__button__icon_arrow " +
                  (!!sortingIsOpen
                    ? "catalog-id__filter__button__icon_arrow-open"
                    : "")
                }
              />
            </button>
            <button className="catalog-id__filter__button" onClick={() => {}}>
              <FilterIcon className="catalog-id__filter__button__icon catalog-id__filter__button__icon_filter" />
              <Typography
                variant="subtitle2"
                className="catalog-id__filter__button__text"
              >
                Фильтры
              </Typography>
            </button>
          </div>

          {[...data1, ...data2, ...data1].map((el) => (
            <div className="cart__list__item" key={el.id}>
              <img
                src={el.image}
                className="cart__list__item__image"
                alt="product"
              />
              <div className="cart__list__item__content">
                <div className="cart__list__item__content__item">
                  <div className="cart__list__item__title">{el.title}</div>
                </div>
                <div className="cart__list__item__content__item">
                  <div className="cart__list__item__label">{el.label}</div>
                </div>
                <div className="cart__list__item__content__item">
                  <div className="cart__list__item__price">{el.price}</div>
                  <Button
                    style={{ marginTop: -20, width: 34 }}
                    variant="second"
                    size="small"
                    startIcon={<EditIcon style={{ marginLeft: 7 }} />}
                    children={undefined}
                    to={paths.accountProductsAdd}
                    state={{ edit: true }}
                  />
                </div>
              </div>
            </div>
          ))}

          <Button
            full
            size="medium"
            variant="outlined"
            style={{ marginBottom: 16 }}
          >
            Показать еще
          </Button>

          <Pagination />
        </div>
      </div>

      <Sorting isOpen={sortingIsOpen} setOpen={setSortingIsOpen} />

      <Footer />
    </>
  );
};

export default AccountProductsPage;
