import { Accordion } from "@szhsin/react-accordion";

import ProductCertificates from "./ProductCertificates";
import ProductDescription from "./ProductDescription";
import ProductInstructions from "./ProductInstructions";
import ProductProtocols from "./ProductProtocols";

import "./styles.scss";

const ProductAccordion = () => {
  return (
    <Accordion transition transitionTimeout={250}>
      <ProductDescription />
      <ProductInstructions />
      <ProductProtocols />
      <ProductCertificates />
    </Accordion>
  );
};

export default ProductAccordion;
