import Header from "components/Header";
import Title from "components/Title";
import Typography from "components/Typography";

const FiltersPage = () => {
  const title = "Фильтры";

  return (
    <div className="wrapper">
      <Title>{title}</Title>

      <Header goBack title={title} />

      <div className="catalog">
        <Typography variant="h3" style={{ marginBottom: 8 }}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

export default FiltersPage;
