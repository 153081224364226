import { Link } from "react-router-dom";

import { paths } from "constants/paths";

import { ReactComponent as MirIcon } from "assets/icons/mir.svg";
import { ReactComponent as SbpIcon } from "assets/icons/sbp-icon.svg";

import "./styles.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="wrapper">
          <div className="footer__menus">
            <nav className="footerMenu">
              <p className="footerMenu__title">Информация</p>

              <Link to={paths.about} className="footnote footerMenu__item">
                О компании
              </Link>

              <Link to={paths.brands} className="footnote footerMenu__item">
                Бренды
              </Link>

              <Link to={paths.policy} className="footnote footerMenu__item">
                Правовая информация
              </Link>

              <Link to={paths.contacts} className="footnote footerMenu__item">
                Контакты
              </Link>
            </nav>
          </div>

          <div className="footer__contacts">
            <div className="footerContact">
              <p className="footnote footerContact__title">
                Телефон для специалистов
              </p>
              <p className="subheadM footerContact__text">
                <a href="tel:+7 (900) 220-98-80">+7 (900) 220-98-80</a>
              </p>
            </div>

            <div className="footerContact">
              <p className="footnote footerContact__title">
                Телефон для производителей
              </p>
              <p className="subheadM footerContact__text">
                <a href="tel:+7 (900) 220-98-81">+7 (900) 220-98-81</a>
              </p>
            </div>

            <div className="footer__bottom">
              <p className="caption2 footer__copyright">
                &copy; 2024 Все права защищены
              </p>

              <div className="footerPayment">
                <div className="footerPayment__item">
                  <MirIcon />
                </div>
                <div className="footerPayment__item">
                  <SbpIcon style={{ height: 18 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
