import { CSSProperties } from "react";
import Typography from "components/Typography";

import { ReactComponent as FileIcon } from "assets/icons/file-check.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/burger-close.svg";

import "./styles.scss";
import Button from "components/Button";
import { UploadedFile } from "components/FileUploader/FileUploader";

type FileProps = {
  file: UploadedFile;
  style?: CSSProperties;
  onDelete?: (id: number) => void;
};

const File: React.FC<FileProps> = ({ file, style, onDelete }) => {
  return (
    <div style={{ flexDirection: "row", display: "flex", gap: 8 }}>
      <div className="file" style={style}>
        <FileIcon />
        <Typography style={{ width: "100%" }}>{file.name}</Typography>
      </div>

      {onDelete && (
        <Button
          variant="second"
          onClick={() => onDelete?.(file.id)}
          startIcon={<RemoveIcon />}
          size="small"
        />
      )}
    </div>
  );
};

export default File;
