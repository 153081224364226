import Title from "components/Title";
import Header from "components/Header";
import Tabs from "components/Tabs";
import Crumbs from "components/Crumbs";
import Typography from "components/Typography";
import Footer from "components/Footer";
import Sorting from "components/Sorting";
import Catalog from "components/Catalog";

import Events from "components/Events";

import useCatalogBrandPage from "./useCatalogBrandPage";

import brandImage from "assets/images/brands/13.png";

import "./styles.scss";

const CatalogBrandPage = () => {
  const {
    title,
    sortingIsOpen,
    setSortingIsOpen,
    show,
    onChangeShow,
    location,
  } = useCatalogBrandPage();

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header />

        <Crumbs path={title} />

        <div className="catalog-brand">
          <div className="catalog-brand__header">
            <div className="catalog-brand__header__inner">
              <img
                src={brandImage}
                className="catalog-brand__image"
                alt="brand"
              />
              <Typography className="catalog-brand__title">
                Академия Эстетики
              </Typography>
            </div>
            <Typography className="catalog-brand__header__description">
              Компания «Академия Эстетики» придерживается философии
              бизнес-партнерства. Квалифицированные тренеры компании и
              приглашенные иностранные специалисты проводят практикумы, живые
              демонстрации и мастер-классы. <br />
              <br />
              Среди наших клиентов — ведущие клиники и медицинские центры
              страны.
            </Typography>
          </div>

          <Tabs brands onChange={onChangeShow} current={show} full />
        </div>

        {show === "catalog" ? (
          <Catalog edit={location?.state?.edit} />
        ) : (
          <div style={{ marginTop: 24 }}>
            <Events edit={location?.state?.edit} />
          </div>
        )}
      </div>

      <Sorting isOpen={sortingIsOpen} setOpen={setSortingIsOpen} />

      <Footer />
    </>
  );
};

export default CatalogBrandPage;
