import brand1 from "assets/images/brands/1.png";
import brand2 from "assets/images/brands/2.png";
import brand3 from "assets/images/brands/3.png";
import brand4 from "assets/images/brands/4.png";
import brand5 from "assets/images/brands/5.png";
import brand6 from "assets/images/brands/6.png";
import brand7 from "assets/images/brands/7.png";
import brand8 from "assets/images/brands/8.png";
import brand9 from "assets/images/brands/9.png";
import brand10 from "assets/images/brands/10.png";
import brand11 from "assets/images/brands/11.png";
import brand12 from "assets/images/brands/12.png";

export const brandsData = [
  {
    id: 1,
    title: "ColibriDerm Hydro",
    image: brand1,
  },
  {
    id: 2,
    title: "S5",
    image: brand2,
  },
  {
    id: 3,
    title: "WOWLAB",
    image: brand3,
  },
  {
    id: 4,
    title: "Dysplastic Skin",
    image: brand4,
  },
  {
    id: 5,
    title: "IONTO",
    image: brand5,
  },
  {
    id: 6,
    title: "ISSEIMI",
    image: brand6,
  },
  {
    id: 7,
    title: "GLACEE Skincare",
    image: brand7,
  },
  {
    id: 8,
    title: "ATLANTIS",
    image: brand8,
  },
  {
    id: 9,
    title: "IPH",
    image: brand9,
  },
  {
    id: 10,
    title: "VIVA Cell+",
    image: brand10,
  },
  {
    id: 11,
    title: "Heel",
    image: brand11,
  },
  {
    id: 12,
    title: "RatioNutriLife (RNL)",
    image: brand12,
  },
];
