import { AccordionItem } from "@szhsin/react-accordion";
import Typography from "components/Typography";

import { ReactComponent as ArrowIcon } from "assets/icons/faq-arrow.svg";

const ProductDescription = () => {
  return (
    <AccordionItem
      header={
        <>
          Описание
          <ArrowIcon className="chevron-down" />
        </>
      }
      initialEntered
    >
      <div className="product-accordion">
        <Typography className="product-accordion__text">
          Nithya Stimulate – мезопрепарат итальянского производства, на основе
          инновационной формулы с содержанием коллагена. Препарат применяется в
          качестве естественного омолаживающего комплекса в области лица,
          оказывает улучшение упругости и эластичности кожи, глубоко увлажняет
          ткани и приостанавливает преждевременное старение дермы.
        </Typography>

        <Typography variant="subtitle1">Состав</Typography>

        <ul>
          <li>
            <Typography>Тертрапептид-2</Typography>
          </li>
          <li>
            <Typography>Гидролизированный коллаген</Typography>
          </li>
          <li>
            <Typography>Гиалуронат натрия</Typography>
          </li>
        </ul>

        <Typography variant="subtitle1">Показания к применению</Typography>

        <ul>
          <li>
            <Typography>
              Лечение хроно- и фотостарении кожи в области лица, глаз, шеи, зоны
              декольте и кистей рук;
            </Typography>
          </li>
          <li>
            <Typography>
              Профилактика преждевременного старения поверхности дермы;
            </Typography>
          </li>
          <li>
            <Typography>Нормализация гидробаланса в тканях;</Typography>
          </li>
          <li>
            <Typography>Комплексная терапия омоложения кожи;</Typography>
          </li>
          <li>
            <Typography>Общее оздоровление дермы;</Typography>
          </li>
          <li>
            <Typography>Уменьшение рубцов, стрий;</Typography>
          </li>
          <li>
            <Typography>
              Подготовка и реабилитация кожи, после агрессивных процедур и
              операций;
            </Typography>
          </li>
          <li>
            <Typography>Регенерация тканей;</Typography>
          </li>
          <li>
            <Typography>
              Восстановление барьерных функций к негативным внешним факторам.
            </Typography>
          </li>
        </ul>

        <Typography variant="subtitle1">Противопоказания</Typography>
        <Typography>
          К противопоказаниям мезотерапии Нития Стимулейт относят: период
          беременности и грудного вскармливания; лица, не достигшие
          совершеннолетнего возраста; наличие воспалительных и инфекционных
          процессов организма; поврежденная кожа в месте проведения инъекции;
          онкологические заболевания; нарушения свертываемости крови.
        </Typography>
      </div>
    </AccordionItem>
  );
};

export default ProductDescription;
