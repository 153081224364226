import { Route, Routes } from "react-router-dom";

import { paths } from "constants/paths";

import AboutPage from "pages/AboutPage";
import BrandsPage from "pages/BrandsPage";
import ContactsPage from "pages/ContactsPage";
import HomePage from "pages/HomePage";
import LicensesPage from "pages/LicensesPage";
import PolicyPage from "pages/PolicyPage";
import ShippingPage from "pages/ShippingPage";

import CatalogBrandPage from "pages/CatalogBrandPage";
import CatalogBrandsPage from "pages/CatalogBrandsPage";
import CatalogPage from "pages/CatalogPage";
import CatalogIdPage from "pages/CatalogPage/CatalogIdPage";
import EventsPage from "pages/EventsPage";
import EventsIdPage from "pages/EventsPage/EventsIdPage";
import FiltersPage from "pages/FiltersPage";
import ProductPage from "pages/ProductPage";

import CartPage from "pages/CartPage";
import OrderPage from "pages/OrderPage";

import AccountPage from "pages/AccountPage";
import AccountBrandsCreate from "pages/AccountPage/AccountBrandsCreate";
import AccountBrandsPage from "pages/AccountPage/AccountBrandsPage";
import AccountEventsAddPage from "pages/AccountPage/AccountEventsAddPage";
import AccountEventsPage from "pages/AccountPage/AccountEventsPage";
import AccountOrdersIdPage from "pages/AccountPage/AccountOrdersIdPage";
import AccountOrdersPage from "pages/AccountPage/AccountOrdersPage";
import AccountProductsAddPage from "pages/AccountPage/AccountProductsAddPage";
import AccountProductsPage from "pages/AccountPage/AccountProductsPage";
import AccountProfilePage from "pages/AccountPage/AccountProfilePage";
import SupportPage from "pages/SupportPage";

import ForgotPasswordPage from "pages/Auth/ForgotPassword";
import PasswordPage from "pages/Auth/PasswordPage";
import SignInPage from "pages/Auth/SignInPage";
import SignUpClinicPage from "pages/Auth/SignUpClinicPage";
import SignUpCosmetologistPage from "pages/Auth/SignUpCosmetologistPage";
import SignUpPage from "pages/Auth/SignUpPage";

const Router = () => {
  return (
    <Routes>
      <Route path={paths.home} element={<HomePage />} />
      <Route path={paths.about} element={<AboutPage />} />
      <Route path={paths.brands} element={<BrandsPage />} />
      <Route path={paths.contacts} element={<ContactsPage />} />
      <Route path={paths.licenses} element={<LicensesPage />} />
      <Route path={paths.policy} element={<PolicyPage />} />
      <Route path={paths.shipping} element={<ShippingPage />} />

      <Route path={paths.catalog} element={<CatalogPage />} />
      <Route path={paths.catalogId} element={<CatalogIdPage />} />
      <Route path={paths.filters} element={<FiltersPage />} />
      <Route path={paths.productId} element={<ProductPage />} />
      <Route path={paths.catalogBrands} element={<CatalogBrandsPage />} />
      <Route path={paths.catalogBrandsId} element={<CatalogBrandPage />} />
      <Route path={paths.events} element={<EventsPage />} />
      <Route path={paths.eventsId} element={<EventsIdPage />} />

      <Route path={paths.cart} element={<CartPage />} />
      <Route path={paths.order} element={<OrderPage />} />
      <Route path={paths.signIn} element={<SignInPage />} />
      <Route path={paths.signUp} element={<SignUpPage />} />
      <Route path={paths.signUpClinic} element={<SignUpClinicPage />} />
      <Route
        path={paths.signUpCosmetologist}
        element={<SignUpCosmetologistPage />}
      />
      <Route path={paths.forgotPassword} element={<ForgotPasswordPage />} />
      <Route path={paths.password} element={<PasswordPage />} />
      <Route path={paths.account} element={<AccountPage />} />
      <Route path={paths.accountProfile} element={<AccountProfilePage />} />
      <Route path={paths.accountOrders} element={<AccountOrdersPage />} />
      <Route path={paths.accountOrdersId} element={<AccountOrdersIdPage />} />
      <Route path={paths.accountBrands} element={<AccountBrandsPage />} />
      <Route path={paths.accountProducts} element={<AccountProductsPage />} />
      <Route
        path={paths.accountProductsAdd}
        element={<AccountProductsAddPage />}
      />
      <Route path={paths.accountEvents} element={<AccountEventsPage />} />
      <Route path={paths.accountEventsAdd} element={<AccountEventsAddPage />} />
      <Route
        path={paths.accountBrandsCreate}
        element={<AccountBrandsCreate />}
      />
      <Route path={paths.support} element={<SupportPage />} />
    </Routes>
  );
};

export default Router;
