import Typography from "components/Typography";
import Button from "components/Button";

import "./styles.scss";

type CardProps = {
  id: number;
  title: string;
  brand: string;
  price: string;
  image: string;
};

const Card: React.FC<CardProps> = ({ id, title, brand, price, image }) => {
  return (
    <div className="card">
      <img src={image} className="card__image" alt={"title"} />

      <Typography className="card__brand" variant="body1">
        {brand}
      </Typography>
      <Typography className="card__title" variant="body1">
        {title}
      </Typography>
      <Typography className="card__price" variant="subtitle1">
        {price}
      </Typography>

      <Button full onClick={() => {}} size="small">
        В корзину
      </Button>
    </div>
  );
};

export default Card;
