import { Link } from "react-router-dom";
import { paths } from "constants/paths";
import Crumbs from "components/Crumbs";
import Footer from "components/Footer";
import Header from "components/Header";
import Title from "components/Title";
import Typography from "components/Typography";

import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as BrandIcon } from "assets/icons/brand.svg";
import { ReactComponent as OrdersIcon } from "assets/icons/orders.svg";
import { ReactComponent as ProductsIcon } from "assets/icons/products.svg";
import { ReactComponent as EventsIcon } from "assets/icons/events.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/exit.svg";

import "./styles.scss";

const AccountPage = () => {
  const title = "Личный кабинет";

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header />

        <div className="account">
          <Crumbs path={title || ""} />
          <Typography variant="h3">ООО «Косметика PRO»</Typography>
          <div className="account__header">
            <Typography className="account__header__item">
              Ломоносов Евгений Николаевич
            </Typography>
            <Typography className="account__header__item">
              userexample@mail.ru
            </Typography>
            <Typography className="account__header__item">
              +7 952 325 96 50
            </Typography>
            <div className="account__header__label">Бренд</div>
          </div>
          <div className="account__menu">
            <div className="account__menu__item">
              <nav className="account__menu__nav">
                <Link
                  to={paths.accountProfile}
                  className="account__menu__nav__item"
                >
                  <div className="account__menu__nav__icon">
                    <UserIcon />
                  </div>
                  <Typography className="account__menu__nav__title">
                    Личные данные
                  </Typography>
                </Link>
                <Link
                  to={paths.accountOrders}
                  className="account__menu__nav__item"
                >
                  <div className="account__menu__nav__icon">
                    <OrdersIcon />
                  </div>
                  <Typography className="account__menu__nav__title">
                    Заказы
                  </Typography>
                </Link>
                <Link
                  to={paths.accountBrands}
                  className="account__menu__nav__item"
                >
                  <div className="account__menu__nav__icon">
                    <BrandIcon />
                  </div>
                  <Typography className="account__menu__nav__title">
                    Мои бренды
                  </Typography>
                </Link>
                <Link
                  to={paths.accountProducts}
                  className="account__menu__nav__item"
                >
                  <div className="account__menu__nav__icon">
                    <ProductsIcon />
                  </div>
                  <Typography className="account__menu__nav__title">
                    Товары
                  </Typography>
                </Link>
                <Link
                  to={paths.accountEvents}
                  className="account__menu__nav__item"
                >
                  <div className="account__menu__nav__icon">
                    <EventsIcon />
                  </div>
                  <Typography className="account__menu__nav__title">
                    События
                  </Typography>
                </Link>
                <Link
                  to={paths.home}
                  className="account__menu__nav__item account__menu__nav__item-logout"
                >
                  <div className="account__menu__nav__icon">
                    <LogoutIcon />
                  </div>
                  <Typography className="account__menu__nav__title">
                    Выйти
                  </Typography>
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AccountPage;
