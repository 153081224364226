import Header from "components/Header";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";
import { paths } from "constants/paths";

import "./styles.scss";

const PasswordPage = () => {
  const title = "Сменить пароль";

  return (
    <>
      <div className="wrapper">
        <Title>{title}</Title>

        <Header goBack title={title} />

        <div className="password">
          <Input
            required
            label="Старый пароль"
            placeholder="Введите пароль"
            className="sign-up__input"
            type="password"
          />
          <Input
            required
            label="Новый пароль"
            placeholder="Введите пароль"
            className="sign-up__input"
            type="password"
          />
          <Input
            required
            label="Повторите новый пароль"
            placeholder="Введите пароль"
            className="sign-up__input"
            type="password"
          />

          <Button full size="large" to={paths.accountProfile}>
            Сохранить
          </Button>
        </div>
      </div>
    </>
  );
};

export default PasswordPage;
