import { JSX } from "react/jsx-runtime";
import { Link, useNavigate } from "react-router-dom";

import Crumbs from "components/Crumbs";
import Typography from "components/Typography";
import Card from "components/Card";
import Button from "components/Button";
import Pagination from "components/Pagination";

import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as FilterIcon } from "assets/icons/filter-icon.svg";

import { paths } from "constants/paths";
import { productsData } from "../../pages/CatalogPage/data/products";

import { ReactComponent as AddIcon } from "assets/icons/plus-white.svg";

import "./styles.scss";

type CatalogProps = {
  title?: string;
  setSortingIsOpen?: (open: boolean) => void;
  sortingIsOpen?: boolean;
  edit?: boolean;
};

export const Catalog: React.FC<CatalogProps> = ({
  edit,
  title,
  sortingIsOpen,
  setSortingIsOpen,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="catalog-id">
        {edit && (
          <Button
            to={paths.accountProductsAdd}
            startIcon={<AddIcon />}
            full
            style={{ marginTop: 24 }}
          >
            Добавить товар
          </Button>
        )}
        <Crumbs path={title || ""} />
        <Typography variant="h3">{title}</Typography>

        <div className="catalog-id__filter">
          <button
            className="catalog-id__filter__button"
            onClick={() => {
              setSortingIsOpen?.(true);
            }}
          >
            <Typography
              variant="subtitle2"
              className="catalog-id__filter__button__text"
            >
              По наименованию
            </Typography>
            <ArrowIcon
              className={
                "catalog-id__filter__button__icon catalog-id__filter__button__icon_arrow " +
                (!!sortingIsOpen
                  ? "catalog-id__filter__button__icon_arrow-open"
                  : "")
              }
            />
          </button>
          <button
            className="catalog-id__filter__button"
            onClick={() => {
              navigate(paths.filters);
            }}
          >
            <FilterIcon className="catalog-id__filter__button__icon catalog-id__filter__button__icon_filter" />
            <Typography
              variant="subtitle2"
              className="catalog-id__filter__button__text"
            >
              Фильтры
            </Typography>
          </button>
        </div>

        <Typography className="catalog-id__result" variant="body1">
          Найдено 198 товаров
        </Typography>

        <div className="catalog-id__products">
          {productsData?.map(
            (
              product: JSX.IntrinsicAttributes & {
                id: number;
                title: string;
                brand: string;
                price: string;
                image: string;
              }
            ) => (
              <Link
                key={product.id}
                to={`${paths.product}/${product.id}`}
                state={{ product }}
                className="catalog-id__product"
              >
                <Card {...product} />
              </Link>
            )
          )}
        </div>
      </div>

      <Button
        full
        size="medium"
        variant="outlined"
        style={{ marginBottom: 16 }}
      >
        Показать еще
      </Button>

      <Pagination />
    </>
  );
};

export default Catalog;
