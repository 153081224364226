import Typography from "components/Typography";

import "./styles.scss";
import { CSSProperties, DetailedHTMLProps, SelectHTMLAttributes } from "react";
import { ControllerFieldState } from "react-hook-form";

export type Option = {
  value: number;
  label: string;
};

type SelectProps = {
  label?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  options: Option[] | undefined;
  value?: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  disabled?: boolean;
} & Partial<ControllerFieldState> &
  DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

const Select: React.FC<SelectProps> = ({
  label,
  className,
  required,
  options,
  onChange,
  style,
  error,
  placeholder,
  ...selectProps
}) => {
  return (
    <div className={`select ${className}`} style={style}>
      {label && (
        <Typography
          className={`select__label ${
            required ? "select__label_required" : ""
          }`}
        >
          {label}
        </Typography>
      )}
      <div className="select__area">
        <select
          {...selectProps}
          className="select__text-area"
          onChange={(e) => onChange?.(e.target.value)}
        >
          {placeholder && (
            <option value="" disabled selected>
              {placeholder}
            </option>
          )}
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {error && (
        <Typography className={`select__error`}>{error.message}</Typography>
      )}
    </div>
  );
};

export default Select;
