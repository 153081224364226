import { CSSProperties } from "react";

import "./styles.scss";

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2";

type TypographyProps = {
  variant?: Variant;
  style?: CSSProperties;
  children: React.ReactNode;
  className?: string;
};

const Typography: React.FC<TypographyProps> = ({
  variant = "body1",
  className,
  children,
  style,
}) => {
  if (variant === "h3") {
    return (
      <h1 className={`h3 ${className}`} style={style}>
        {children}
      </h1>
    );
  }

  if (variant === "subtitle1") {
    return (
      <p className={`subtitle1 ${className}`} style={style}>
        {children}
      </p>
    );
  }

  if (variant === "subtitle2") {
    return (
      <p className={`subtitle2 ${className}`} style={style}>
        {children}
      </p>
    );
  }

  return (
    <p className={`body1 ${className}`} style={style}>
      {children}
    </p>
  );
};

export default Typography;
