export const faqData = [
  {
    id: 1,
    title: "Как заказывать продукцию на платформе COSMETICZONE?",
    description:
      "После регистрации и верификации Вы получаете доступ ко всем возможностям платформы.",
  },
  {
    id: 2,
    title: "Чья продукция представлена на платформе?",
    description:
      "На нашей платформе представлены только производители косметологических товаров и проверенные официальные представители зарубежных производителей.",
  },
  {
    id: 3,
    title: "Как осуществляется доставка?",
    description:
      "Доставка оплаченного товара осуществляется удобным для Вас способом. Как в личном кабинете, так и в приложении транспортной компании Вы можете отслеживать все этапы передвижения заказа. Вы можете выбрать как транспортную компанию, так и способ доставки (самовывоз из пункта выдачи или доставкой курьером до двери)",
  },
  {
    id: 4,
    title:
      "Какие документы нужны для регистрации?",
    description:
      "Для регистрации на платформе необходимо заполнит форму, прикрепить фотографии документов (паспорт, диплом о медицинском образовании, диплом (свидетельство) косметолога)",
  },
  {
    id: 5,
    title:
      "Что такое Календарь событий?",
    description:
      "Календарь событий-это раздел, в котором Вы сможете отслеживать будущие мероприятия, связанные с индустрией. Удобно планировать свой график для того, чтобы не пропустить интересные для себя события",
  },
];
